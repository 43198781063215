import { ReactNode } from "react";
import {
    AppBar, Box,
    Button,
    Icon, IconButton, 
    Toolbar,
    Tooltip,
    Typography
} from "@mui/material";

import { useAppThemeContext } from "../contexts";


interface IBasePageLayoutProps {
    children: ReactNode;
    toolbar?: ReactNode;
    title: string;
    onLogin: () => void;
}

export const PublicBasePageLayout: React.FC<IBasePageLayoutProps> = ({
    children,
    toolbar,
    title,
    onLogin }) => {

    const { toggleTheme, themeName } = useAppThemeContext();

    const handleClickToggleTheme = () => {
        toggleTheme();
    }

    return (
        <Box height="100%" display="flex" flexDirection="column" gap={1}>

            <AppBar position="static">
                <Box margin={1} flexDirection="column">
                    <Toolbar disableGutters>

                        <Box sx={{ flexGrow: 1, display: 'flex' }}>

                            <Typography
                                variant="h6"
                                sx={{
                                    display: { xs: 'flex', md: 'flex', lg: 'none', xl: 'none' },
                                    fontWeight: 500,
                                    letterSpacing: '.1rem',
                                    color: 'inherit',
                                    textDecoration: 'none',
                                }}
                                flex={1}
                                title={"Gestão Eletrônica de Extensão Universitária"}
                            >
                                {title}
                            </Typography>

                            <Typography
                                variant="h4"
                                component="a"
                                sx={{
                                    mr: 2,
                                    display: { xs: 'none', md: 'none', lg: 'flex', xl: 'flex' },
                                    flexGrow: 1,
                                    fontWeight: 500,
                                    letterSpacing: '.1rem',
                                    color: 'inherit',
                                    textDecoration: 'none',
                                }}
                                flex={1}
                                title="Gestão Eletrônica de Extensão Universitária"
                            >
                                {title}
                            </Typography>

                        </Box>
                        <Box sx={{ flexGrow: 0 }}>

                            <Tooltip title="Alterar tema">
                                <IconButton
                                    onClick={handleClickToggleTheme}
                                    size="small"
                                    sx={{ ml: 2 }}
                                >
                                    <Icon>
                                        {
                                            themeName === "dark" ? "light_mode" : "dark_mode"
                                        }
                                    </Icon>
                                </IconButton>
                            </Tooltip>

                            <Button color="inherit" variant="outlined" onClick={onLogin}>Login</Button>
                        </Box>

                    </Toolbar>
                </Box>
            </AppBar>

            {
                toolbar &&
                <Box>
                    {toolbar}
                </Box>
            }

            <Box flex={1} overflow="auto">
                {children}
            </Box>
        </Box>
    );
}