export async function fileToArrayBuffer(file: File): Promise<Uint8Array> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = (event) => {
            if (event.target && event.target.result) {
                const arrayBuffer = event.target.result as ArrayBuffer;
                const uint8Array = new Uint8Array(arrayBuffer);
                resolve(uint8Array);
            } else {
                reject(new Error('Failed to read file'));
            }
        };

        reader.onerror = (error) => {
            reject(error);
        };

        reader.readAsArrayBuffer(file);
    });
}

export async function fileToBase64(file: File): Promise<string> {
    const uint8Array = await fileToArrayBuffer(file);

    // Método para converter o Uint8Array para uma string Base64
    let binary = '';
    const len = uint8Array.byteLength;
    for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(uint8Array[i]);
    }
    
    // Usando btoa para converter a string binária para Base64
    const base64String = btoa(binary);

    return base64String;
}

export function formatDateString(dateString: string) {
    // Extrai componentes da data (assume formato "YYYY-MM-DDTHH:mm:ss" ou similar)
    const [datePart, timePart] = dateString.split('T');
    const [year, month, day] = datePart.split('-').map(Number);
    const [hours, minutes] = timePart ? timePart.split(':').map(Number) : [0, 0];

    // Cria um objeto Date no fuso horário local
    const date = new Date(year, month - 1, day, hours, minutes);

    // Formata a data para "dd/MM/yyyy H:mm"
    const formattedDate = [
        date.getDate().toString().padStart(2, '0'),
        (date.getMonth() + 1).toString().padStart(2, '0'),
        date.getFullYear(),
    ].join('/') + ' ' + [
        date.getHours().toString().padStart(2, '0'),
        date.getMinutes().toString().padStart(2, '0'),
    ].join(':');

    return formattedDate;
}