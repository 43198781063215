import { ReactNode, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
    AppBar, Avatar, Box,
    Icon, IconButton, ListItemIcon, Menu,
    MenuItem, Theme, Toolbar,
    Typography, useMediaQuery
} from "@mui/material";

import { useAuthContext, useDrawerContext } from "../contexts";

interface IBasePageLayoutProps {
    children: ReactNode;
    title: string;
    toolbar?: ReactNode;
}

export const BasePageLayout: React.FC<IBasePageLayoutProps> = ({ children, title, toolbar }) => {

    const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

    const { toggleDrawerOpen } = useDrawerContext();
    const { name, logout } = useAuthContext();
    const navigate = useNavigate();

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        logout();
    };

    return (
        <Box height="100%" display="flex" flexDirection="column" gap={1}>

            <AppBar position="static">
                <Box margin={1} flexDirection="column">
                    <Toolbar disableGutters>

                        {
                            smDown &&
                            <IconButton
                                onClick={toggleDrawerOpen}
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                color="inherit"
                            >
                                <Icon>menu</Icon>
                            </IconButton>
                        }

                        <Box sx={{ flexGrow: 1, display: 'flex' }}>

                            <Typography
                                variant="h6"
                                sx={{
                                    display: { xs: 'flex', md: 'flex', lg: 'none', xl: 'none' },
                                    fontWeight: 500,
                                    letterSpacing: '.1rem',
                                    color: 'inherit',
                                    textDecoration: 'none',
                                }}
                                flex={1}
                                title="Gestão Eletrônica de Extensão Universitária"
                            >
                                {title ? title : ""}
                            </Typography>

                            <Typography
                                variant="h4"
                                component="a"
                                sx={{
                                    mr: 2,
                                    display: { xs: 'none', md: 'none', lg: 'flex', xl: 'flex' },
                                    flexGrow: 1,
                                    fontWeight: 500,
                                    letterSpacing: '.1rem',
                                    color: 'inherit',
                                    textDecoration: 'none',
                                }}
                                flex={1}
                                title="Gestão Eletrônica de Extensão Universitária"
                            >
                                {title}
                            </Typography>

                        </Box>

                        <Box sx={{ flexGrow: 0 }}>

                            <IconButton
                                onClick={handleClick}
                                size="small"
                                sx={{ ml: 2 }}
                                aria-controls={open ? 'account-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                            >
                                <Avatar sx={{ width: 32, height: 32 }}>
                                    {name.charAt(0)}
                                </Avatar>
                            </IconButton>
                        </Box>

                        <Menu
                            anchorEl={anchorEl}
                            id="account-menu"
                            open={open}
                            onClose={handleClose}
                            onClick={handleClose}
                            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                        >

                            <MenuItem onClick={() => {
                                handleClose();
                                navigate("/about");
                            }}>
                                <ListItemIcon color="primary">
                                    <Icon fontSize="small">info</Icon>
                                </ListItemIcon>
                                Sobre
                            </MenuItem>

                            <MenuItem onClick={() => {
                                handleClose();
                                handleLogout();
                                navigate("/");
                            }}>
                                <ListItemIcon>
                                    <Icon fontSize="small">logout</Icon>
                                </ListItemIcon>
                                Sair
                            </MenuItem>
                        </Menu>
                    </Toolbar>
                </Box>
            </AppBar>

            {
                toolbar &&
                <Box>
                    {toolbar}
                </Box>
            }

            <Box flex={1} overflow="auto">
                {children}
            </Box>
        </Box>
    );
}