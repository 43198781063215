import * as React from 'react';
import { useEffect, useState } from 'react';
import { useField } from '@unform/core';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/pt-br'; // Importa o locale em português

// Configura o dayjs para usar o português como idioma padrão
dayjs.locale('pt-br');

type BasicDatePickerProps = {
  name: string;
  label?: string;
};

export const VDatePicker: React.FC<BasicDatePickerProps> = ({ name, label }) => {
  const { fieldName, registerField, defaultValue, error, clearError } = useField(name);
  const [value, setValue] = useState<Dayjs | null>(defaultValue ? dayjs(defaultValue) : null);

  useEffect(() => {
    registerField({
      name: fieldName,
      getValue: () => value ? value.format('YYYY-MM-DD') : null,
      setValue: (_, newValue) => setValue(newValue ? dayjs(newValue) : null),
      clearValue: () => setValue(null),
    });
  }, [registerField, fieldName, value]);

  const handleDateChange = (newValue: Dayjs | null) => {
    setValue(newValue);
    if (error) clearError();
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
        <DatePicker
          label={label || "Data"}
          format="DD/MM/YYYY" // Formato brasileiro
          value={value}
          onChange={handleDateChange}
          slotProps={{
            textField: {
              error: !!error,
              helperText: error || '',
              fullWidth: true,
            },
          }}
        />
    </LocalizationProvider>
  );
};
