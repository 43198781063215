import { ReactNode, forwardRef, useState } from "react";
import {
    AppBar, Box, Button, Checkbox,
    Dialog, FormControlLabel,
    Grid, LinearProgress, Slide, TextField, Toolbar, Typography
} from "@mui/material";
import * as yup from "yup";
import InputMask from "react-input-mask";
import { TransitionProps } from "notistack";

import { useAuthContext } from "../../contexts";
import { Environment } from "../../environment";
import { UserService } from "../../services/api";
import { Conditional } from "../conditional/conditional";

const Transition = forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function validarMatricula(matricula: string): boolean {
    // Verificar se a matrícula possui exatamente 9 caracteres (incluindo os pontos)
    if (matricula.length !== 9) {
        return false;
    }

    const anoAtual = new Date().getFullYear().toString().slice(-2); // Obtém os dois últimos dígitos do ano atual

    // Verificar se os primeiros dois dígitos representam um ano válido (não pode ser maior que o ano atual)
    const anoDigitos = parseInt(matricula.slice(0, 2));
    if (anoDigitos > parseInt(anoAtual)) {
        return false;
    }

    // Verificar se o terceiro caractere é um ponto
    if (matricula.charAt(2) !== '.') {
        return false;
    }

    // Verificar se o quarto caractere representa um semestre válido (de 1 a 2)
    const semestreDigitos = parseInt(matricula[3]);
    if (semestreDigitos < 1 || semestreDigitos > 2) {
        return false;
    }

    // Verificar se o quinto caractere é um ponto
    if (matricula.charAt(4) !== '.') {
        return false;
    }

    // Se todas as validações passaram, retorna true para uma matrícula válida
    return true;
}

const optInSchema = yup.object().shape({
    registration: yup.string().required().min(9).test(
        'test-invalid-registration',
        'Matrícula inválida',
        (matricula) => validarMatricula(matricula))
});

interface IOptInLGPDProps {
    children: ReactNode
}

export const OptInLGPD: React.FC<IOptInLGPDProps> = ({ children }) => {

    const { optInLGPD, roles, userId, updateOptInLGPD } = useAuthContext();

    const [open, setOpen] = useState(true);
    const [registration, setRegistration] = useState('');
    const [agreed, setAgreed] = useState(false);
    const [loading, setLoading] = useState(false);
    const [registrationError, setRegistrationError] = useState('');

    const handleAgreementChange = (event: any) => {
        setAgreed(event.target.checked);
    };

    const handleSubmit = () => {

        setLoading(true);

        if (roles?.includes(Environment.ROLE_ALUNO)) {

            optInSchema
                .validate({ registration }, { abortEarly: false })
                .then(validateData => {
                    UserService.optInLGPD({ userId: userId, registration: validateData.registration })
                        .then((result) => {

                            if (result instanceof Error) {
                                return;
                            }

                            setOpen(false);
                            localStorage.setItem(Environment.LOCAL_STORAGE_KEY_ACCESS_TOKEN, JSON.stringify(result.token));

                        })
                        .finally(() => {
                            setLoading(false);
                        });
                })
                .catch((errors: yup.ValidationError) => {
                    setLoading(false);
                    errors.inner.forEach(error => {
                        if (error.path === "registration") {
                            setRegistrationError(error.message);
                        }
                    });

                })
        }
        else {
            UserService.optInLGPD({ userId: userId })
                .then((result) => {

                    if (result instanceof Error) {
                        return;
                    }

                    setOpen(false);
                    localStorage.setItem(Environment.LOCAL_STORAGE_KEY_ACCESS_TOKEN, JSON.stringify(result.token));
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }

    if (optInLGPD)
        return (<>{children}</>)

    return (

        <Dialog
            fullScreen
            open={open}
            // onClose={handleClose}
            TransitionComponent={Transition}
            onTransitionExited={updateOptInLGPD}
        >
            <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <Typography sx={{ ml: 2, flex: 1 }} textAlign="center" variant="h6" component="div">
                        Termo de Consentimento para Coleta e Uso de Dados no Sistema GEEU
                    </Typography>
                </Toolbar>
            </AppBar>

            <Conditional condition={loading}>
                <LinearProgress variant='indeterminate' />
            </Conditional>

            <Grid container justifyContent="center" alignItems="center" style={{ height: '100%' }}>
                <Grid item xs={10} sm={8} md={6}>
                    <Box p={3} textAlign="justify">
                        <Typography variant="body1" paragraph>
                            Ao utilizar o sistema GEEU, você concorda com a coleta e o uso de informações pessoais para fins de acesso e funcionalidade do sistema. Os dados coletados podem incluir, mas não estão limitados a:
                            <br />
                            - Dados de identificação, como nome completo, e-mail e curso relacionado.
                            <br />
                            - Finalidade da Coleta: Permitir o acesso e utilização do sistema GEEU.
                            <br />
                            - Responsabilidade do Uso dos Dados: Os dados coletados serão utilizados exclusivamente para os fins mencionados acima.
                            <br />
                            - Armazenamento dos Dados: Concordo que as informações fornecidas podem ser armazenadas no sistema GEEU durante o período de utilização deste sistema.
                            <br />
                            - Direitos do Titular dos Dados: O titular dos dados tem o direito de solicitar acesso, correção, exclusão ou portabilidade dos seus dados pessoais, conforme permitido pela legislação aplicável.
                            <br />
                            Concordo que ao utilizar este sistema, você consente voluntariamente com a coleta e uso dos seus dados pessoais conforme descrito neste termo.
                        </Typography>
                    </Box>

                    <Conditional condition={roles?.includes(Environment.ROLE_ALUNO)}>

                        <Box textAlign="center">
                            <Typography variant="h6" paragraph>
                                Informe a sua matrícula
                            </Typography>

                            <InputMask
                                mask="99.9.9999"
                                value={registration}
                                onChange={e => setRegistration(e.target.value)}
                            >
                                <TextField
                                    label="Matrícula"
                                    name="registration"
                                    variant="outlined"
                                    margin="normal"
                                    error={!!registrationError}
                                    helperText={registrationError}
                                    onKeyDown={() => setRegistrationError('')}
                                />
                            </InputMask>
                        </Box>

                    </Conditional>

                    <Box p={3} textAlign="center">

                        <FormControlLabel
                            control={<Checkbox checked={agreed} onChange={handleAgreementChange} color="primary" />}
                            label="Concordo"
                        />

                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSubmit}
                            disabled={!agreed || loading}
                            style={{ marginTop: '15px' }}
                        >
                            Confirmar
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        </Dialog>

    );
};