import { Chip } from '@mui/material';

enum ReportStatus {
    Undefined = -1,
    PendingApproval = 0,
    Approved = 1,
    DisapprovedForReview = 2,
    Disapproved = 3
}

interface ReportStatusChipProps {
    status: ReportStatus;
}

export const ReportStatusChip: React.FC<ReportStatusChipProps> = ({ status }) => {

    const getStatusLabel = (status: ReportStatus): {
        label: string;
        color:
        'primary' |
        'secondary' |
        'warning' |
        'error' |
        'success' |
        'info'
    } => {

        switch (status) {
            case ReportStatus.PendingApproval:
                return { label: 'Aprovação Pendente', color: 'warning' };
            case ReportStatus.Approved:
                return { label: 'Aprovado', color: 'success' };
            case ReportStatus.DisapprovedForReview:
                return { label: 'Reprovado para Revisão', color: 'warning' };
            case ReportStatus.Disapproved:
                return { label: 'Reprovado', color: 'error' };
            default:
                return { label: 'Desconhecido', color: 'secondary' };
        }

    };

    const { label, color } = getStatusLabel(status);

    return (
        <Chip label={label} color={color} size="small"/>
    );
};
