import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Grid, LinearProgress, Paper, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import * as yup from 'yup';

import { DetailToolbar } from "../../shared/components";
import { useVForm, IVFormErrors, VForm, VSelectOne, VTextField } from "../../shared/forms";
import { ICoursePost, IDepartmentResponse } from "../../shared/interfaces";
import { BasePageLayout } from "../../shared/layouts";
import { DepartmentService, CourseService } from "../../shared/services/api";

const formValidationSchema: yup.Schema<ICoursePost> = yup.object().shape({
    departmentId: yup
        .number()
        .required(),
    name: yup
        .string()
        .required()
        .min(5)
        .max(50),
    acronym: yup
        .string()
        .required()
        .min(2)
        .max(10),
});

export const CourseForm: React.FC = () => {
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    const {
        formRef, save, saveAndBack, isSaveAndBack
    } = useVForm();

    const [loading, setLoading] = useState<boolean>(false);
    const [allDepartments, setAllDepartments] = useState<IDepartmentResponse[]>([]);

    const countRef = useRef(0);
    
    useEffect(() => {

        if (process.env.NODE_ENV === 'development') {
            if (countRef.current === 0) {
                countRef.current = 1;
                return;
            }
        }

        formRef.current?.setData({
            nome: ''
        });

        handleGetAllDepartments();
    }, [formRef])

    const handleGetAllDepartments = () => {

        if (allDepartments.length === 0) {

            DepartmentService.getAll()
                .then((result) => {

                    if (result instanceof Error) {
                        return;
                    }

                    setAllDepartments(result);

                })
        }
    }

    const handleSave = (formData: ICoursePost) => {

        formValidationSchema.
            validate(formData, { abortEarly: false })
            .then((validateDatas) => {

                setLoading(true);

                CourseService.create(validateDatas)
                    .then((result) => {

                        if (result instanceof Error) {
                            return;
                        }

                        enqueueSnackbar(
                            <Typography>
                                Curso cadastrado com sucesso.
                            </Typography>,
                            { variant: 'success' });

                        if (isSaveAndBack())
                            navigate("/courses");

                    })
                    .finally(() => {
                        setLoading(false);
                    }); setLoading(true);

            })
            .catch((errors: yup.ValidationError) => {
                const validationErrors: IVFormErrors = {}

                errors.inner.forEach(error => {

                    if (!error.path) return;

                    validationErrors[error.path] = error.message;
                });

                formRef.current?.setErrors(validationErrors);
            });

    };

    return (
        <BasePageLayout
            title="Novo Curso"
            toolbar={
                <DetailToolbar
                    showButtonDelete={false}
                    showButtonAdd={false}
                    showButtonSaveAndBack
                    showButtonSaveLoading={loading}
                    showButtonSaveAndBackLoading={loading}
                    showButtonBackLoading={loading}

                    onClickButtonSave={save}
                    onClickButtonSaveAndBack={saveAndBack}
                    onClickButtonBack={() => navigate("/courses", { replace: true })}
                />
            }
        >

            <VForm ref={formRef} onSubmit={handleSave}>
                <Box margin={1} display="flex" flexDirection="column" component={Paper}>


                    <Grid container direction="column" padding={1} spacing={2}>

                        {
                            loading &&
                            <Grid item>
                                <LinearProgress variant='indeterminate' />
                            </Grid>
                        }
                        <Grid item>
                            <Typography variant="h6">Dados do curso</Typography>
                        </Grid>

                        <Grid container item direction="row" spacing={2}>
                            <Grid item
                                xs={12}
                                md={8}
                                lg={6}
                                xl={3}
                            >
                                <VSelectOne
                                    name="departmentId"
                                    options={allDepartments.map(department => ({ id: department.id, text: `${department.acronym} - ${department.name}` }))}
                                    label="Departamento"
                                />
                            </Grid>
                        </Grid>

                        <Grid container item direction="row" spacing={2}>
                            <Grid item
                                xs={12}
                                md={8}
                                lg={6}
                                xl={3}
                            >
                                <VTextField
                                    label="Nome"
                                    name="name"
                                    fullWidth
                                    required
                                    toUpper
                                    disabled={loading}
                                />
                            </Grid>
                        </Grid>

                        <Grid container item direction="row" spacing={2}>
                            <Grid item
                                xs={4}
                                md={4}
                                lg={2}
                                xl={2}
                            >
                                <VTextField
                                    label="Sigla"
                                    name="acronym"
                                    fullWidth
                                    required
                                    toUpper
                                    disabled={loading}
                                />
                            </Grid>
                        </Grid>

                    </Grid>

                </Box>
            </VForm>

        </BasePageLayout>
    )
};