import axios from "axios";
import { jwtDecode } from "jwt-decode";

import { errorInterceptor, publicErrorInterceptor, responseInterceptor } from "./interceptors";
import { Environment } from "../../../environment";

const Api = axios.create({
    baseURL: Environment.BASE_URL
});

const PublicApi = axios.create({
    baseURL: Environment.BASE_URL
});

Api.interceptors.response.use(
    (response) => responseInterceptor(response),
    (error) => errorInterceptor(error),
);

PublicApi.interceptors.response.use(
    (response) => responseInterceptor(response),
    (error) => publicErrorInterceptor(error),
);

Api.interceptors.request.use((config) => {

    try {

        if (localStorage.getItem(Environment.LOCAL_STORAGE_KEY_ACCESS_TOKEN) !== null) {

            const expiration = localStorage.getItem(Environment.LOCAL_STORAGE_KEY_EXPIRATION)?.replace(/^"(.*)"$/, '$1');

            const tokenExpiration = new Date(String(expiration)); // Data de expiração do token
            const currentDate = new Date(); // Data atual

            if (tokenExpiration < currentDate) {
                localStorage.removeItem(Environment.LOCAL_STORAGE_KEY_ACCESS_TOKEN);
                localStorage.removeItem(Environment.LOCAL_STORAGE_KEY_EXPIRATION);

                window.location.pathname = "/"
            }
            else {
                const accessToken = localStorage.getItem(Environment.LOCAL_STORAGE_KEY_ACCESS_TOKEN);

                if (accessToken) {
                    try {
                        const parsedToken = JSON.parse(accessToken);

                        config.headers.Authorization = `Bearer ${parsedToken}`;
                    } catch (error) {

                        throw error;
                    }
                }

            }
        }


    } catch (error) {
        throw error;
    }

    return config;

}, function (error) {
    return Promise.reject(error);
});



export { Api, PublicApi };