import {
    Box, Button, Icon, IconButton,
    Paper, TextField, Theme,
    useMediaQuery, useTheme
} from "@mui/material";

import { Environment } from "../../environment";

interface IListingToolbarProps {
    searchText?: string;
    onChangeSearchText?: (newText: string) => void;
    onClickSearch: () => void;
}

export const ListingPublicExtensionProjectToolbar: React.FC<IListingToolbarProps> = ({
    searchText = '',
    onChangeSearchText,
    onClickSearch,
}) => {

    const theme = useTheme();

    const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

    return (
        <Box
            gap={1}
            marginX={1}
            padding={1}
            paddingX={2}
            display="flex"
            alignItems="center"
            height={theme.spacing(6)}
            component={Paper}
        >

            <TextField
                size="small"
                value={searchText}
                onChange={(e) => onChangeSearchText?.(e.target.value)}
                placeholder={Environment.SEARCH_INPUT}
            />
            {
                !smDown
                &&
                (
                    <Box
                        flex={1}
                        gap={1}
                        display="flex" justifyContent="end"
                    >

                        <Button
                            variant="contained"
                            color="primary"
                            endIcon={<Icon>search</Icon>}
                            onClick={onClickSearch}
                        >
                            Pesquisar
                        </Button>

                    </Box>
                )
            }

            {
                smDown
                &&
                (
                    <Box
                        flex={1}
                        gap={1}
                        display="flex" justifyContent="end"
                    >

                        <IconButton
                            color="primary"
                            size="small"
                            onClick={onClickSearch}
                        >
                            <Icon>search</Icon>
                        </IconButton>

                    </Box>
                )
            }


        </Box >
    );
}