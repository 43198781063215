import { Environment } from "../../../environment";
import {
    IExtensionProjectSearchResponse, IPublicExtensionProjectSearchResponse, ICourse_ExtensionProjectSearchResponse,
    IExtensionProjectResponse, IExtensionProjectPost, IExtensionProjectPut, IApproveExtensionProjectPut,
    IDisapproveExtensionProject, IApplyForExtensionProjectPut, IParticipationApprovalPut, ICourse_ExtensionProjectPost2,
    ICourse_ExtensionProjectResponse, IUser_ExtensionProjectParticipationStatusLogSearchResponse, ITransferResponsibilityRequest
} from "../../../interfaces";
import { Api, PublicApi } from "../axios-config";


const controller = "ExtensionProject"

const search = async (
    name: string,
    userId: number = 0,
    status?: number,
    openForApplication?: boolean,
    listCourseId?: string,
    rowsPerPage: number = Environment.ROWS_LIMIT10,
    page: number = 0
): Promise<IExtensionProjectSearchResponse | Error> => {

    try {

        var request = `${controller}?Name=${name}`;

        if (userId > 0) {
            request += `&UserId=${userId}`;
        }

        if (status !== undefined) {
            request += `&Status=${status}`;
        }

        if (openForApplication !== undefined) {
            request += `&OpenForApplications=${openForApplication}`;
        }

        if (listCourseId) {
            listCourseId.split(',').forEach((courseId) => {
                request += `&ListCourseId=${courseId}`;
            });
        }

        const { data } = await Api.get<IExtensionProjectSearchResponse>
            (`${request}&RowsPerPage=${rowsPerPage}&Page=${page}`);

        if (data) {
            return data;
        }

        return new Error("Erro ao buscar os projetos de extensão.");

    } catch (error) {
        console.error(error);

        return new Error((error as { message: string }).message || "Erro ao buscar os projetos de extensão.");
    }
}

const searchPublicProjects = async (
    name: string,
    rowsPerPage: number = Environment.ROWS_LIMIT10,
    page: number = 0
): Promise<IPublicExtensionProjectSearchResponse | Error> => {

    try {

        var request = `${controller}/SearchPublicExtensionProject?Name=${name}`;

        const { data } = await PublicApi.get<IPublicExtensionProjectSearchResponse>
            (`${request}&RowsPerPage=${rowsPerPage}&Page=${page}`);

        if (data) {
            return data;
        }

        return new Error("Erro ao buscar os projetos de extensão.");

    } catch (error) {
        console.error(error);

        return new Error((error as { message: string }).message || "Erro ao buscar os projetos de extensão.");
    }
}

const getExtensionProjectPendingApproval = async (userId: number, page: number = 0, rowsPerPage: number = Environment.ROWS_LIMIT10): Promise<ICourse_ExtensionProjectSearchResponse | Error> => {

    try {

        const { data } = await Api.get<ICourse_ExtensionProjectSearchResponse>(`${controller}/GetExtensionProjectPendingApproval?userId=${userId}&page=${page}&rowsPerPage=${rowsPerPage}`);

        if (data) {
            return data;
        }

        return new Error("Erro ao buscar os projetos de extensão.");

    } catch (error) {
        console.error(error);

        return new Error((error as { message: string }).message || "Erro ao buscar os projetos de extensão.");
    }
}

const getById = async (id: number): Promise<IExtensionProjectResponse | Error> => {

    try {

        const { data } = await Api.get<IExtensionProjectResponse>(`${controller}/${id}`);

        if (data) {
            return data;
        }

        return new Error("Erro ao buscar o projeto de extensão.");

    } catch (error) {
        console.error(error);

        return new Error((error as { message: string }).message || "Erro ao buscar o projeto de extensão.");
    }

}

const getExtensionProjectParticipants = async (id: number): Promise<IExtensionProjectResponse | Error> => {

    try {

        const { data } = await Api.get<IExtensionProjectResponse>(`${controller}/getExtensionProjectParticipants/${id}`);

        if (data) {
            return data;
        }

        return new Error("Erro ao buscar participantes.");

    } catch (error) {
        console.error(error);

        return new Error((error as { message: string }).message || "Erro ao buscar o projeto de extensão.");
    }

}




const create = async (extensionProject: IExtensionProjectPost): Promise<IExtensionProjectResponse | Error> => {

    try {

        const { data } = await Api.post<IExtensionProjectResponse>(`${controller}`, extensionProject);

        if (data) {
            return data;
        }

        return new Error("Erro ao criar projeto de extensão.");

    } catch (error) {
        console.error(error);

        return new Error((error as { message: string }).message || "Erro ao criar projeto de extensão.");
    }
}

const update = async (extensionProject: IExtensionProjectPut): Promise<IExtensionProjectResponse | Error> => {

    try {

        const { data } = await Api.put<IExtensionProjectResponse>(`${controller}`, extensionProject);

        if (data) {
            return data;
        }

        return new Error("Erro ao atualizar projeto de extensão.");

    } catch (error) {
        console.error(error);

        return new Error((error as { message: string }).message || "Erro ao atualizar projeto de extensão.");
    }
}

const approveExtensionProject = async (approveExtensionProject: IApproveExtensionProjectPut): Promise<IExtensionProjectResponse | Error> => {

    try {

        const { data } = await Api.put<IExtensionProjectResponse>(`${controller}/ApproveExtensionProject`, approveExtensionProject);

        if (data) {
            return data;
        }

        return new Error("Erro ao aprovar projeto de extensão.");

    } catch (error) {
        console.error(error);

        return new Error((error as { message: string }).message || "Erro ao aprovar projeto de extensão.");
    }
}

const disapproveExtensionProject = async (disapproveExtensionProject: IDisapproveExtensionProject): Promise<IExtensionProjectResponse | Error> => {

    try {

        const { data } = await Api.put<IExtensionProjectResponse>(`${controller}/DisapproveExtensionProject`, disapproveExtensionProject);

        if (data) {
            return data;
        }

        return new Error("Erro ao rejeitar projeto de extensão.");

    } catch (error) {
        console.error(error);

        return new Error((error as { message: string }).message || "Erro ao rejeitar projeto de extensão.");
    }
}

const ApplyForExtensionProject = async (applyForExtensionProjectPut: IApplyForExtensionProjectPut): Promise<any | Error> => {

    try {

        const { data } = await Api.put<any>(`${controller}/ApplyForExtensionProject`, applyForExtensionProjectPut);

        if (data) {
            return data;
        }

    } catch (error) {
        console.error(error);

        return new Error((error as { message: string }).message || "Erro ao candidatar no projeto de extensão.");
    }
}

const ApproveParticipation = async (participationApprovalPut: IParticipationApprovalPut): Promise<IExtensionProjectResponse | Error> => {

    try {

        const { data } = await Api.put<IExtensionProjectResponse>(`${controller}/ApproveParticipation`, participationApprovalPut);

        if (data) {
            return data;
        }

        return data;

    } catch (error) {
        console.error(error);

        return new Error((error as { message: string }).message || "Erro ao aprovar participação do usuário no projeto de extensão.");
    }
}

const DisapproveParticipation = async (participationApprovalPut: IParticipationApprovalPut): Promise<IExtensionProjectResponse | Error> => {

    try {

        const { data } = await Api.put<IExtensionProjectResponse>(`${controller}/DisapproveParticipation`, participationApprovalPut);

        if (data) {
            return data;
        }

        return new Error("Erro reprovar participação.");

    } catch (error) {
        console.error(error);

        return new Error((error as { message: string }).message || "Erro ao reprovar participação do usuário no projeto de extensão.");
    }
}

const FinalizeTeacherParticipation = async (participationApprovalPut: IParticipationApprovalPut): Promise<IExtensionProjectResponse | Error> => {

    try {

        const { data } = await Api.put<IExtensionProjectResponse>(`${controller}/FinalizeTeacherParticipation`, participationApprovalPut);

        if (data) {
            return data;
        }

        return new Error("Erro finalizar participação.");

    } catch (error) {
        console.error(error);

        return new Error((error as { message: string }).message || "Erro ao finalizar participação do usuário no projeto de extensão.");
    }
}



const getAtachment = async (extensionProjectId: number): Promise<any | Error> => {

    try {

        var response = await Api.get<File>(`${controller}/GetAttachment/${extensionProjectId}`, {
            responseType: 'blob',
            headers: {
                'Accept': 'application/pdf'
            }
        });

        const contentDisposition = response.headers['content-disposition'];
        const match = contentDisposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/);

        var fileName = match ? decodeURIComponent(match[1].replace(/\+/g, ' ')) : 'extensionProject.pdf';

        fileName = fileName.replace(/^"(.*)"$/, '$1');

        const blob = new Blob([response.data], { type: 'application/pdf' });

        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;

        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        window.URL.revokeObjectURL(link.href);

        return null;

    } catch (error) {
        console.error(error);

        return new Error((error as { message: string }).message || "Erro ao fazer download do anexo do projeto de extensão.");
    }
}

const FinalizeExtensionProject = async (extensionProjectId: number): Promise<IExtensionProjectResponse | Error> => {

    try {

        const { data } = await Api.put<IExtensionProjectResponse>(`${controller}/FinalizeExtensionProject/${extensionProjectId}`,);

        if (data) {
            return data;
        }

        return new Error("Erro reprovar participação.");

    } catch (error) {
        console.error(error);

        return new Error((error as { message: string }).message || "Erro ao reprovar participação do usuário no projeto de extensão.");
    }
}

const OpenForApplication = async (extensionProjectId: number): Promise<IExtensionProjectResponse | Error> => {

    try {

        const { data } = await Api.put<IExtensionProjectResponse>(`${controller}/OpenForApplication/${extensionProjectId}`,);

        if (data) {
            return data;
        }

        return new Error("Erro reprovar participação.");

    } catch (error) {
        console.error(error);

        return new Error((error as { message: string }).message || "Erro ao reprovar participação do usuário no projeto de extensão.");
    }
}

const CloseForApplication = async (extensionProjectId: number): Promise<IExtensionProjectResponse | Error> => {

    try {

        const { data } = await Api.put<IExtensionProjectResponse>(`${controller}/CloseForApplication/${extensionProjectId}`,);

        if (data) {
            return data;
        }

        return new Error("Erro reprovar participação.");

    } catch (error) {
        console.error(error);

        return new Error((error as { message: string }).message || "Erro ao reprovar participação do usuário no projeto de extensão.");
    }
}

const addCourseExtensionProject = async (course_ExtensionProject: ICourse_ExtensionProjectPost2): Promise<ICourse_ExtensionProjectResponse | Error> => {

    try {

        const { data } = await Api.post<ICourse_ExtensionProjectResponse>(`${controller}/AddCourseExtensionProject`, course_ExtensionProject);

        if (data) {
            return data;
        }

        return data;

    } catch (error) {
        console.error(error);

        return new Error((error as { message: string }).message || "Erro ao adicionar curso ao projeto de extensão.");
    }
}

const deleteCourseExtensionProject = async (extensioProjectId: number, courseId: number): Promise<any | Error> => {

    try {

        const { data } = await Api.delete(`${controller}/DeleteCourseExtensionProject/${extensioProjectId}/${courseId}`);

        if (data) {
            return data;
        }

        return data;

    } catch (error) {
        console.error(error);

        return new Error((error as { message: string }).message || "Erro ao remover curso do projeto de extensão.");
    }
}

const SearchUser_ExtensionProjectParticipationStatusLog = async (
    userId: number,
    extensionProjectId?: number): Promise<IUser_ExtensionProjectParticipationStatusLogSearchResponse | Error> => {

    try {

        var request = `${controller}/SearchUser_ExtensionProjectParticipationStatusLog?UserId=${userId}&ExtensionProjectId=${extensionProjectId}`;

        const { data } = await Api.get<IUser_ExtensionProjectParticipationStatusLogSearchResponse>
            (`${request}&RowsPerPage=${999}&Page=${0}`);

        if (data) {
            return data;
        }

        return new Error("Erro ao buscar os projetos de extensão.");

    } catch (error) {
        console.error(error);

        return new Error((error as { message: string }).message || "Erro ao buscar os projetos de extensão.");
    }
}

const transferResponsibility = async (transferResponsibilityRequest: ITransferResponsibilityRequest): Promise<IExtensionProjectResponse | Error> => {

    try {

        const { data } = await Api.post<IExtensionProjectResponse>(`${controller}/TransferResponsibility`, transferResponsibilityRequest);

        if (data) {
            return data;
        }

        return data;

    } catch (error) {
        console.error(error);

        return new Error((error as { message: string }).message || "Erro ao transferir a responsabilidade do projeto.");
    }
}

const getExtensionProjectLogs = async (id: number): Promise<IExtensionProjectResponse | Error> => {

    try {

        const { data } = await Api.get<IExtensionProjectResponse>(`${controller}/GetExntensionProjectLogs/${id}`);

        if (data) {
            return data;
        }

        return new Error("Erro ao buscar o projeto de extensão.");

    } catch (error) {
        console.error(error);

        return new Error((error as { message: string }).message || "Erro ao buscar o projeto de extensão.");
    }

}

export const ExtensionProjectService = {
    search,
    searchPublicProjects,
    getById,
    getExtensionProjectParticipants,
    getExtensionProjectLogs,
    create,
    update,
    getExtensionProjectPendingApproval,
    approveExtensionProject,
    disapproveExtensionProject,
    ApplyForExtensionProject,
    ApproveParticipation,
    DisapproveParticipation,
    FinalizeTeacherParticipation,
    getAtachment,
    FinalizeExtensionProject,
    OpenForApplication,
    CloseForApplication,
    addCourseExtensionProject,
    deleteCourseExtensionProject,
    SearchUser_ExtensionProjectParticipationStatusLog,
    transferResponsibility
};