import { useEffect, useState } from "react";
import {
    Select,
    MenuItem,
    SelectProps,
    FormControl,
    InputLabel,
    OutlinedInput,
    Checkbox,
    ListItemText,
    FormHelperText,
    SelectChangeEvent
} from "@mui/material";
import { useField } from "@unform/core";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

interface Option {
    id: string | number;
    text: string;
}

type VSelectProps = SelectProps & {
    name: string;
    options: Option[];
    label: string;
    multiple?: boolean;
};

export const VSelect: React.FC<VSelectProps> = ({
    name,
    options,
    label,
    multiple = true,
    ...rest
}) => {
    const { fieldName, registerField, error } = useField(name);
    const [selectedIds, setSelectedIds] = useState<(string | number)[]>([]);
    const [selectedValues, setSelectedValues] = useState<(string | number)[]>([]);

    useEffect(() => {
        registerField({
            name: fieldName,
            getValue: () => selectedIds,
            setValue: (_, newValue) => {
                setSelectedIds(newValue || []);
                // Atualiza os valores exibidos com base nos IDs selecionados
                const values = options.filter(option => newValue.includes(option.id)).map(option => option.text);
                setSelectedValues(values);
            },
        });
    }, [registerField, fieldName, selectedIds, options]);

    const handleChange = (event: SelectChangeEvent<typeof selectedValues>) => {
        const {
            target: { value },
        } = event;
        setSelectedValues(value as (string | number)[]);
        // Atualiza os IDs selecionados
        const ids = options.filter(option => value.includes(option.text)).map(option => option.id);
        setSelectedIds(ids);
    };

    return (
        <>
            <FormControl fullWidth>
                <InputLabel>{label}</InputLabel>
                <Select
                    multiple={multiple}
                    value={selectedValues}
                    onChange={handleChange}
                    input={<OutlinedInput />}
                    renderValue={(selected) => {
                        if (Array.isArray(selected)) {
                            return selected.join(', ');
                        }
                        return selected as string;
                    }}
                    error={!!error}
                    MenuProps={MenuProps}
                >
                    {options.map((option) => (
                        <MenuItem key={option.id} value={option.text}>
                            <Checkbox checked={selectedValues.includes(option.text)} />
                            <ListItemText primary={option.text} />
                        </MenuItem>
                    ))}
                </Select>
                {error && <FormHelperText style={{ color: "red" }}>{error}</FormHelperText>}
            </FormControl>
        </>
    );
};
