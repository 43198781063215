import { ReactNode, useEffect, useState } from "react";
import {
    Alert,
    Box, Button, Card, CardActions,
    CardContent, Dialog, DialogActions, DialogContent, DialogContentText,
    DialogTitle, LinearProgress, Link, TextField, Typography, useMediaQuery, useTheme
} from "@mui/material";
import * as yup from "yup";

import { cpf as cpfValidate } from 'cpf-cnpj-validator';
import { PublicExtensionProjectsList, About } from "../../../pages";
import { useAuthContext } from "../../contexts";
import { Conditional } from "../conditional/conditional";

const loginSchema = yup.object().shape({
    cpf: yup.string().required().min(11).test(
        'test-invalid-cpf',
        'CPF inválido',
        (cpf) => cpfValidate.isValid(cpf)),
    password: yup.string().required(),
});

interface ILoginProps {
    children: ReactNode
}

export const Login: React.FC<ILoginProps> = ({ children }) => {

    const { isAuthenticated, login } = useAuthContext();

    const [forgottenPasswordDialog, setForgottenPasswordDialog] = useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const [loading, setLoading] = useState(false);
    const [cpf, setCPF] = useState('');
    const [password, setPassword] = useState('');
    const [cpfError, setCPFError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [loginError, setLoginError] = useState<boolean>(false);
    const [showLoginPage, setShowLoginPage] = useState(false);

    useEffect(() => {
        setCPF("");
        setPassword("");
        setShowLoginPage(false);
        setLoginError(false);
    }, [setCPF, setPassword, isAuthenticated, setShowLoginPage, setLoginError]);

    const handleSubmit = () => {

        setLoading(true);

        loginSchema
            .validate({ cpf, password }, { abortEarly: false })
            .then(validateData => {
                setLoginError(false);
                login(
                    {
                        user: validateData.cpf,
                        password: validateData.password
                    }
                ).then((result) => {

                    if (result instanceof Error && result.message !== "Erro de conexão.") {
                        setLoginError(true);
                    }

                })
                    .finally(() => {
                        setLoading(false);
                    });
            })
            .catch((errors: yup.ValidationError) => {
                setLoading(false);
                errors.inner.forEach(error => {
                    if (error.path === "cpf") {
                        setCPFError(error.message);
                    } else if (error.path === "password") {
                        setPasswordError(error.message);
                    }
                });

            })
    }

    if (isAuthenticated)
        return (<>
            {children}
        </>)

    return (
        <>
            <Conditional condition={!showLoginPage}>
                <PublicExtensionProjectsList onLogin={() => setShowLoginPage(true)} />
            </Conditional>
            <Conditional condition={showLoginPage}>
                <Box
                    width="100vw"
                    height="100vh"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    sx={{
                        backgroundImage: `url(https://icea.ufop.br/sites/default/files/styles/os_files_xxlarge/public/icea/files/predios_e_cantina.jpg?m=1617829490&itok=LD3adigM)`, // Substitua com o caminho correto para a sua imagem
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                    }}
                >

                    <Card>

                        {
                            loading &&
                            <LinearProgress variant='indeterminate' />
                        }
                        <Typography variant="h2" align="center"> <b>GEEU</b> </Typography>
                        <Typography align="center"><small>Gestão Eletrônica de Extensão Universitária</small> </Typography>
                        <CardContent>
                            <Box display="flex" flexDirection="column" gap={2} width={300}>
                                <Typography variant="h6" align="center"> Login </Typography>

                                <TextField
                                    fullWidth
                                    label="CPF"
                                    type="tel"
                                    // TODO -  remover depois
                                    //type="password"
                                    value={cpf}
                                    disabled={loading}
                                    error={!!cpfError}
                                    helperText={cpfError}
                                    onChange={e => setCPF(e.target.value)}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            handleSubmit();
                                        }
                                        setCPFError('');
                                    }}
                                />

                                <TextField
                                    fullWidth
                                    label="Senha"
                                    type="password"
                                    value={password}
                                    disabled={loading}
                                    error={!!passwordError}
                                    helperText={passwordError}
                                    onChange={e => setPassword(e.target.value)}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            handleSubmit();
                                        }
                                        setPasswordError('');
                                    }}
                                />

                                <Conditional condition={loginError}>
                                    <Alert severity="error">Usuário e/ou senha incorretos</Alert>
                                </Conditional>

                            </Box>
                            <br />
                            <Box display="flex" justifyContent="center">
                                <Link href="#" onClick={() => setForgottenPasswordDialog(true)}>Esqueci minha senha</Link>
                            </Box>
                        </CardContent>
                        <CardActions>
                            <Box width="100%" display="flex" justifyContent="center">
                                <Button
                                    variant="contained"
                                    onClick={() => setShowLoginPage(false)}
                                    disabled={loading}
                                >
                                    Voltar
                                </Button>
                            </Box>
                            <Box width="100%" display="flex" justifyContent="center">
                                <Button
                                    variant="contained"
                                    onClick={handleSubmit}
                                    disabled={loading}
                                >
                                    Entrar
                                </Button>
                            </Box>
                        </CardActions>

                    </Card>

                    <Dialog
                        fullScreen={fullScreen}
                        open={forgottenPasswordDialog}
                        onClose={() => setForgottenPasswordDialog(false)}
                        aria-labelledby="responsive-dialog-title"
                    >
                        <DialogTitle id="responsive-dialog-title">
                            {"Esqueceu a senha?"}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Para redefinir sua senha, acesse o <Link href="https://moodlepresencial.ufop.br/login/index.php" target="_blank">moodle </Link>
                                ou o <Link href="https://www.minhaufop.ufop.br/minhaufop/login" target="_blank">minha UFOP</Link>.
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button autoFocus onClick={() => setForgottenPasswordDialog(false)}>
                                Ok
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Box>
            </Conditional>
        </>
    );
};