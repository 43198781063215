import React from "react";
import { ReactNode, useState } from "react";
import {
    Drawer, Box, useTheme, Divider,
    List, ListItemButton, ListItemIcon, ListItemText,
    useMediaQuery,
    Icon,
    Typography,
    Collapse,
    Dialog,
    LinearProgress,
    DialogTitle,
    DialogContent,
    Grid,
    DialogActions,
    Button,
    Stack,
    Alert,
    CircularProgress
} from '@mui/material';
import { useMatch, useNavigate, useResolvedPath } from "react-router-dom";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import * as yup from 'yup';

import { useAuthContext, useDrawerContext, useAppThemeContext } from "../../contexts";
import { Environment } from "../../environment";
import { useVForm, IVFormErrors, VForm, VTextField } from "../../forms";
import { ReportManagementService } from "../../services/api";
import { Conditional } from "../conditional/conditional";
import { enqueueSnackbar } from "notistack";

interface ISideMenuProps {
    children: ReactNode
}

interface IValidateCertificateForm {
    hash: string;
}

interface IListItemsMenuProps {
    label: string;
    icon: string;
    redirectTo: string;
    pl?: number;
    display?: boolean;
    onClick: (() => void) | undefined;
}

const ListItemsMenu: React.FC<IListItemsMenuProps> = ({
    label,
    icon,
    redirectTo,
    pl = 2,
    display = true,
    onClick
}) => {

    const navigate = useNavigate();
    const resolvedPath = useResolvedPath(redirectTo);

    const match = useMatch({
        path: resolvedPath.pathname,
        end: false
    });

    const handleClick = () => {
        navigate(redirectTo);
        onClick?.();
    };

    return (
        <ListItemButton selected={!!match} onClick={handleClick} sx={{ pl: pl, display: display ? "" : "none" }}>
            <ListItemIcon>
                <Icon>{icon}</Icon>
            </ListItemIcon>
            <ListItemText primary={label} />
        </ListItemButton>
    );
}

const formValidationSchema: yup.Schema<IValidateCertificateForm> = yup.object().shape({
    hash: yup.string().required()
});

export const SideMenu: React.FC<ISideMenuProps> = ({ children }) => {

    const theme = useTheme();
    const smDown = useMediaQuery(theme.breakpoints.down('md'));

    const { name, roles } = useAuthContext();

    const { isDrawerOpen, toggleDrawerOpen, drawerOptions } = useDrawerContext();
    const { toggleTheme, themeName } = useAppThemeContext();

    const {
        formRef
    } = useVForm();

    const [dialogVerifyCertificateOpen, setDialogVerifyCertificateOpen] = useState(false);
    const [loadingVerifyCertificate, setLoadingVerifyCertificate] = useState(false);
    const [loadingGenerateCertificate, setLoadingGenerateCertificate] = useState(false);
    const [validatedCertificate, setValidatedCertificate] = useState<boolean | undefined>(undefined);
    const [hashValidatedCertificate, setHashValidatedCertificate] = useState<string>("");

    const handleClickToggleTheme = () => {
        toggleTheme();
    }

    const [estados, setEstados] = useState<{ [key: number]: boolean }>({});

    const toggleItem = (id: number) => {
        setEstados((prevEstados) => ({
            ...prevEstados,
            [id]: !prevEstados[id] // Inverte o estado do item com o ID fornecido
        }));
    };

    const handleValidateCertificate = async (formData: IValidateCertificateForm) => {

        formValidationSchema
            .validate(formData, { abortEarly: false })
            .then(async (validateDatas) => {

                setLoadingVerifyCertificate(true);

                ReportManagementService.validateCertificate(validateDatas.hash)
                    .then((result) => {

                        if (result instanceof Error) {
                            return;
                        }

                        setValidatedCertificate(result);

                        if (result) {
                            setHashValidatedCertificate(validateDatas.hash);
                        }
                        else {
                            setHashValidatedCertificate("");
                        }

                    })
                    .finally(() => {
                        setLoadingVerifyCertificate(false);
                    });

            })
            .catch((errors: yup.ValidationError) => {
                const validationErrors: IVFormErrors = {}

                errors.inner.forEach(error => {

                    if (!error.path) return;

                    validationErrors[error.path] = error.message;
                });

                formRef.current?.setErrors(validationErrors);
            });

    }

    const handleDownloadCertificate = () => {
        if (hashValidatedCertificate) {
            setLoadingVerifyCertificate(true);

            ReportManagementService.downloadCertificate(hashValidatedCertificate)
                .then((result) => {

                    if (result instanceof Error) {
                        return;
                    }

                })
                .finally(() => {
                    setLoadingVerifyCertificate(false);
                });
        }
    }

    const handleGenerateCertificate = () => {

        setLoadingGenerateCertificate(true);

        ReportManagementService.generateStudentCertificate()
            .then((result) => {

                if (result instanceof Error) {
                    enqueueSnackbar(
                        <Typography>
                            {result.message}
                        </Typography>,
                        { variant: 'error' })

                    return;
                }

            })
            .finally(() => {
                setLoadingGenerateCertificate(false);
            });
    }


    return (
        <>
            <Drawer
                open={isDrawerOpen}
                variant={smDown ? 'temporary' : 'permanent'}
                onClose={toggleDrawerOpen}>
                <Box
                    width={theme.spacing(28)}
                    height="100%"
                    display="flex"
                    flexDirection="column"
                >
                    <Box
                        width="100%"
                        height={theme.spacing(10)}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                    >

                        <Typography variant="h5">
                            <b>
                                Olá, {name.split(' ')[0].charAt(0).toUpperCase() + name.split(' ')[0].slice(1).toLowerCase()}!
                            </b>
                        </Typography>

                    </Box>

                    <Divider />

                    <Box flex={1}>
                        <List component="nav">
                            {
                                drawerOptions.map((drawerOption) => {
                                    if (!drawerOption.group) {
                                        return (
                                            <ListItemsMenu
                                                key={drawerOption.path}
                                                label={drawerOption.label}
                                                icon={drawerOption.icon}
                                                redirectTo={drawerOption.path}
                                                onClick={smDown ? toggleDrawerOpen : undefined}
                                                display={
                                                    drawerOption.role !== undefined ? roles?.includes(drawerOption.role) : true
                                                }
                                            />
                                        )
                                    }
                                    else {

                                        return (
                                            <React.Fragment key={drawerOption.id + "Fragment"}>
                                                <ListItemButton onClick={() => toggleItem(drawerOption.id)}
                                                    sx={
                                                        {
                                                            display: (drawerOption.role !== undefined ? roles?.includes(drawerOption.role) ? "" : "none" : "") as string
                                                        }
                                                    }
                                                >
                                                    <ListItemIcon>
                                                        <Icon>{drawerOption.icon}</Icon>
                                                    </ListItemIcon>
                                                    <ListItemText primary={drawerOption.label} />
                                                    {estados[drawerOption.id] ? <ExpandLess /> : <ExpandMore />}
                                                </ListItemButton>
                                                <Collapse
                                                    in={estados[drawerOption.id]}
                                                    timeout="auto"
                                                    unmountOnExit
                                                >
                                                    <List component="div" disablePadding>
                                                        {drawerOption.group.map(option => (

                                                            <ListItemsMenu
                                                                key={option.path}
                                                                label={option.label}
                                                                icon={option.icon}
                                                                redirectTo={option.path}
                                                                onClick={smDown ? toggleDrawerOpen : undefined}
                                                                pl={4}
                                                                display={
                                                                    option.role !== undefined ? roles?.includes(option.role) : true
                                                                }
                                                            />
                                                        ))}
                                                    </List>
                                                </Collapse>
                                            </React.Fragment>
                                        )
                                    }
                                })

                            }
                        </List>
                    </Box>

                    <Box>
                        <List component="nav">

                            <Conditional condition={
                                roles?.includes(Environment.ROLE_ALUNO)
                            }>
                                <ListItemButton
                                    disabled={loadingGenerateCertificate}
                                    onClick={() => {
                                        handleGenerateCertificate();
                                    }}>

                                    <ListItemIcon>
                                        <Icon>
                                            assignment_turned_in
                                        </Icon>
                                    </ListItemIcon>
                                    <ListItemText primary="Gerar Certificado" />
                                    <Conditional condition={loadingGenerateCertificate}>
                                        <CircularProgress
                                            size={24}
                                            sx={{

                                                position: 'absolute',
                                                top: '50%',
                                                left: '50%',
                                                marginTop: '-12px',
                                                marginLeft: '-12px',
                                            }}
                                        />
                                    </Conditional>
                                </ListItemButton>
                            </Conditional>

                            <Conditional condition={
                                roles?.includes(Environment.ROLE_PROFESSOR)
                                ||
                                roles?.includes(Environment.ROLE_COLEGIADO)
                                ||
                                roles?.includes(Environment.ROLE_ADMIN)
                            }>
                                <ListItemButton onClick={() => {
                                    setDialogVerifyCertificateOpen(true);
                                    setValidatedCertificate(undefined);
                                }}>
                                    <ListItemIcon>
                                        <Icon>
                                            verified
                                        </Icon>
                                    </ListItemIcon>
                                    <ListItemText primary="Verificar Certificado" />
                                </ListItemButton>
                            </Conditional>

                            <ListItemButton onClick={handleClickToggleTheme}>
                                <ListItemIcon>
                                    <Icon>
                                        {
                                            themeName === "dark" ? "light_mode" : "dark_mode"
                                        }
                                    </Icon>
                                </ListItemIcon>
                                <ListItemText primary="Alternar tema" />
                            </ListItemButton>

                        </List>
                    </Box>
                </Box>
            </Drawer>

            <Conditional condition={
                roles?.includes(Environment.ROLE_PROFESSOR)
                ||
                roles?.includes(Environment.ROLE_COLEGIADO)
                ||
                roles?.includes(Environment.ROLE_ADMIN)
            }>
                <Dialog
                    open={dialogVerifyCertificateOpen}
                    onClose={() => setDialogVerifyCertificateOpen(false)}
                    PaperProps={{
                        component: 'form'
                    }}
                    fullWidth
                    maxWidth="sm"
                >
                    <Conditional condition={loadingVerifyCertificate}>
                        <LinearProgress variant='indeterminate' />
                    </Conditional>

                    <VForm ref={formRef} onSubmit={handleValidateCertificate}>

                        <DialogTitle>
                            Validar Certificado
                        </DialogTitle>

                        <DialogContent>
                            <Grid container direction="column" padding={1} spacing={2}>

                                <Grid item>
                                    <VTextField
                                        label="Hash de Autenticação"
                                        name="hash"
                                        disabled={loadingVerifyCertificate}
                                        fullWidth
                                        required
                                    />
                                </Grid>

                                <Grid item>
                                    <Conditional condition={validatedCertificate !== undefined}>
                                        <Stack sx={{ width: '100%' }} spacing={2}>
                                            <Conditional condition={validatedCertificate === false}>
                                                <Alert severity="error">Chave inválida</Alert>
                                            </Conditional>
                                            <Conditional condition={validatedCertificate === true}>
                                                <Alert severity="success">Chave válida</Alert>
                                            </Conditional>
                                        </Stack>
                                    </Conditional>
                                </Grid>

                            </Grid>

                        </DialogContent>

                        <DialogActions>

                            <Conditional condition={validatedCertificate === true}>
                                <Button
                                    variant="contained"
                                    disabled={loadingVerifyCertificate}
                                    onClick={handleDownloadCertificate}
                                >
                                    Download do Certificado
                                </Button>
                            </Conditional>

                            <Button
                                variant="contained"
                                disabled={loadingVerifyCertificate}
                                onClick={() => setDialogVerifyCertificateOpen(false)}
                            >
                                Cancelar
                            </Button>

                            <Button
                                variant="contained"
                                disabled={loadingVerifyCertificate}
                                onClick={() => formRef.current?.submitForm()}
                            >
                                Validar
                            </Button>

                        </DialogActions>

                    </VForm>
                </Dialog>
            </Conditional>
            <Box height="100vh" marginLeft={smDown ? 0 : theme.spacing(28)}>
                {children}
            </Box>
        </>
    );
}