import { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom"
import {
    Icon, IconButton, LinearProgress, Pagination,
    Paper, Table, TableBody, TableCell, TableContainer,
    TableFooter, TableHead, TableRow, Typography,
    List, ListItem, ListItemText, useMediaQuery, useTheme,
    Divider
} from "@mui/material";
import { useSnackbar } from "notistack";

import { ListingToolbar } from "../../shared/components";
import { Environment } from "../../shared/environment";
import { IDepartmentSearchResponse } from "../../shared/interfaces";
import { BasePageLayout } from "../../shared/layouts";
import { DepartmentService } from "../../shared/services/api";
import { ConfirmDialog } from "../../shared/components/confirmDialog/ConfirmDialog";

export const DepartmentList: React.FC = () => {

    const [searchParam, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const [loading, setLoading] = useState<boolean>(false);
    const [dialogOpen, setDialogOpen] = useState<boolean>(false);
    const [rowCount, setRowCount] = useState<number>(0);
    const [deleteCourseId, setDeleteCourseId] = useState<number>(0);

    const [departmentsList, setDepartmentsList] = useState<IDepartmentSearchResponse>();
    const { enqueueSnackbar } = useSnackbar();

    const searchText = useMemo(() => searchParam.get('search') || '', [searchParam]);
    const page = useMemo(() => Number(searchParam.get('page') || '1'), [searchParam]);

    const countRef = useRef(0);

    useEffect(() => {
        if (process.env.NODE_ENV === 'development') {
            if (countRef.current === 0) {
                countRef.current = 1;
                return;
            }
        }
        handleSearch();
    }, [page]);

    const handleSearch = () => {
        setLoading(true);
        DepartmentService.search(searchText, undefined, page - 1)
            .then((result) => {
                if (result instanceof Error) return;
                setDepartmentsList(result);
                setRowCount(result.rowsCount);
            })
            .finally(() => setLoading(false));
    }

    const handleDelete = () => {
        setDialogOpen(false);
        DepartmentService.deleteById(deleteCourseId)
            .then((result) => {
                if (result instanceof Error) return;
                enqueueSnackbar(
                    <Typography>
                        Departamento apagado com sucesso.
                    </Typography>,
                    { variant: 'success' }
                );
                handleSearch();
            })
            .finally(() => setLoading(false));
    }

    return (
        <BasePageLayout
            title="Departamentos"
            toolbar={(
                <ListingToolbar
                    showSearchIpunt
                    searchText={searchText}
                    onChangeSearchText={text => setSearchParams({ search: text, page: '1' }, { replace: true })}
                    onClickButtonAdd={() => navigate("/departments/new")}
                    onClickSearch={() => handleSearch()}
                />
            )}
        >
            <TableContainer component={Paper} variant="outlined" sx={{ m: 1, width: 'auto' }}>
                {loading && <LinearProgress variant="indeterminate" />}
                {!isMobile ? (
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>ID</TableCell>
                                <TableCell>Nome</TableCell>
                                <TableCell>Sigla</TableCell>
                                <TableCell>Ações</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {departmentsList?.items?.map(row => (
                                <TableRow key={row.id}>
                                    <TableCell>{row.id}</TableCell>
                                    <TableCell>{row.name}</TableCell>
                                    <TableCell>{row.acronym}</TableCell>
                                    <TableCell>
                                        <IconButton
                                            onClick={() => {
                                                setDialogOpen(true);
                                                setDeleteCourseId(row.id);
                                            }}
                                            title="Excluir registro"
                                        >
                                            <Icon>delete</Icon>
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                        {rowCount === 0 && !loading && <caption>Nenhum registro encontrado</caption>}
                    </Table>
                ) : (
                    <List>
                        {departmentsList?.items?.map(row => (
                            <>
                                <ListItem key={row.id} button>
                                    <ListItemText
                                        primary={row.name}
                                        secondary={row.acronym}
                                    />
                                    <IconButton
                                        edge="end"
                                        aria-label="delete"
                                        onClick={() => {
                                            setDialogOpen(true);
                                            setDeleteCourseId(row.id);
                                        }}
                                    >
                                        <Icon>delete</Icon>
                                    </IconButton>
                                </ListItem>
                                <Divider />
                            </>
                        ))}
                        {rowCount === 0 && !loading && "Nenhum registro encontrado"}
                    </List>
                )}
                {loading && <LinearProgress variant="indeterminate" />}
                {rowCount > 0 && rowCount > Environment.ROWS_LIMIT10 && (
                    <TableFooter>
                        <TableRow>
                            <TableCell colSpan={3}>
                                <Pagination
                                    page={page}
                                    count={Math.ceil(rowCount / Environment.ROWS_LIMIT10)}
                                    onChange={(_, newPage) => setSearchParams({ search: searchText, page: newPage.toString() }, { replace: true })}
                                />
                            </TableCell>
                        </TableRow>
                    </TableFooter>

                )}
            </TableContainer>

            <ConfirmDialog
                dialogTitle="Deletar Departamento"
                dialogDescription="Desseja deletar este departamento?"
                onClose={() => setDialogOpen(false)}
                onConfirm={handleDelete}
                open={dialogOpen}
            />
        </BasePageLayout>
    )
}
