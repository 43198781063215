import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useField } from '@unform/core';
import { FormHelperText, Typography } from '@mui/material';

type IInputFileUpload = {
    name: string;
    accept: string;
    buttonLabel?: string;
};

type InputProps = JSX.IntrinsicElements['input'] & IInputFileUpload;

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

export const InputFileUpload: React.FC<InputProps> = ({
    name,
    accept,
    buttonLabel = "Upload",
    ...rest
}) => {

    const inputRef = useRef<HTMLInputElement>(null);

    const { fieldName, registerField, defaultValue, error, clearError } = useField(name);
    const [value, setValue] = useState(defaultValue || '');
    const [fileName, setFileName] = useState<string | null>(null);

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRef.current,
            setValue: (_, newValue) => {
                if (typeof newValue === 'string') {
                    setValue(newValue);
                    setFileName(newValue.split('\\').pop() || null); // Atualiza o nome do arquivo
                } else {
                    setValue('');
                    setFileName(null);
                }
            },
            getValue(ref: HTMLInputElement) {
                return ref.files && Array.from(ref.files);
            },
            clearValue(ref: HTMLInputElement) {
                ref.value = '';
                setFileName(null); // Limpa o nome do arquivo
            },
        });
    }, [fieldName, registerField]);

    return (
        <>
            <Button component="label" variant="contained" startIcon={<CloudUploadIcon />}>
                {buttonLabel}
                <VisuallyHiddenInput
                    type="file"
                    accept={accept}
                    onChange={(e) => {
                        setValue(e.target.value);
                        setFileName(e.target.files ? e.target.files[0].name : null); // Define o nome do arquivo selecionado
                        error && clearError(); // Limpa o erro quando há uma alteração
                    }}
                    ref={inputRef}
                    defaultValue={defaultValue}
                />
            </Button>
            {fileName && (
                <Typography variant="body2" sx={{ marginTop: 1 }}>
                    Arquivo selecionado: {fileName}
                </Typography>
            )}
            {error && <FormHelperText style={{ color: "red" }}>{error}</FormHelperText>}
        </>
    );
}
