import { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
    Divider,
    Icon, IconButton, LinearProgress, List, ListItem, ListItemText,
    Pagination, Paper, Table, TableBody, TableCell,
    TableContainer, TableFooter, TableHead, TableRow, Tooltip, Typography,
    useMediaQuery, useTheme
} from "@mui/material";
import { Conditional, ExtensionProjectStatusChip, ParticipationStatusChip } from "../../shared/components";
import { useAuthContext } from "../../shared/contexts";
import { Environment } from "../../shared/environment";
import { IExtensionProjectSearchResponse, Course_ExtensionProjectResponse, IStudentExtensionProjectSearchResponse } from "../../shared/interfaces";
import { BasePageLayout } from "../../shared/layouts";
import { ExtensionProjectService, UserService } from "../../shared/services/api";

export const Home = () => {
    const { userId, roles } = useAuthContext();
    const [searchParam, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const [loading, setLoading] = useState<boolean>(false);
    const [rowCount, setRowCount] = useState<number>(0);
    const [extensionProjectList, setExtensionProjectList] = useState<IExtensionProjectSearchResponse>();
    const [usersList, setUsersList] = useState<IStudentExtensionProjectSearchResponse>();

    const page = useMemo(() => {
        return Number(searchParam.get('page') || '1');
    }, [searchParam]);

    const countRef = useRef(0);

    useEffect(() => {
        if (process.env.NODE_ENV === 'development') {
            if (countRef.current === 0) {
                countRef.current = 1;
                return;
            }
        }
        if (roles.includes(Environment.ROLE_ALUNO)) {
            handleSearchStudentPaticipations();
        } else {
            handleSearch();
        }
    }, [page]);

    const handleSearch = () => {
        setLoading(true);
        ExtensionProjectService.search("", userId, undefined, undefined, undefined, Environment.ROWS_LIMIT10, page - 1)
            .then((result) => {
                if (!(result instanceof Error)) {
                    setExtensionProjectList(result);
                    setRowCount(result.rowsCount);
                }
            })
            .finally(() => setLoading(false));
    };

    const handleSearchStudentPaticipations = () => {
        setLoading(true);
        UserService.getStudendParticipations(userId, page - 1).then((result) => {
            if (!(result instanceof Error)) {
                setUsersList(result);
                setRowCount(result.rowsCount);
            }
        }).finally(() => setLoading(false));
    };

    return (
        <BasePageLayout
            title={roles.includes(Environment.ROLE_ALUNO) ? "Home - Minhas Participações" : "Home - Meus Projetos de Extensão"}
        >
            {!roles.includes(Environment.ROLE_ALUNO) ? (
                isMobile ? (
                    <List>
                        {extensionProjectList?.items?.map(row => (
                            <>
                                <ListItem key={`${row.id}-item-ep-home`} onClick={() => navigate(`/extension-projects/${row.id}`)} button>
                                    <ListItemText
                                        primary={row.name}
                                        secondary={`Responsável: Professor ${row.responsibleUser?.name} | Cursos: ${row.course_ExtensionProjects?.map((cep: Course_ExtensionProjectResponse) => cep.course?.acronym).join(', ')}`}
                                    />
                                    <ExtensionProjectStatusChip status={row.status} />
                                </ListItem>
                                <Divider />
                            </>
                        ))}
                    </List>
                ) : (
                    <TableContainer component={Paper} variant="outlined" sx={{ m: 1, width: 'auto' }}>
                        {loading && <LinearProgress variant="indeterminate" />}
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>ID</TableCell>
                                    <TableCell>Nome</TableCell>
                                    <TableCell>Responsável</TableCell>
                                    <TableCell>Curso</TableCell>
                                    <TableCell>Status</TableCell>
                                    <TableCell>Ações</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {extensionProjectList?.items?.map(row => (
                                    <TableRow key={`${row.id}-item-ep-home`}>
                                        <TableCell>{row.id}</TableCell>
                                        <TableCell>{row.name}</TableCell>
                                        <TableCell>Professor {row.responsibleUser?.name}</TableCell>
                                        <TableCell>
                                            {row.course_ExtensionProjects?.map((cep: Course_ExtensionProjectResponse, index: number) => (
                                                <Tooltip title={cep?.course?.name} key={`${index}-item-ep-home-course`}>
                                                    <span>{index + 1 === row.course_ExtensionProjects?.length ? `${cep?.course?.acronym}` : `${cep?.course?.acronym}, `}</span>
                                                </Tooltip>
                                            ))}
                                        </TableCell>
                                        <TableCell><ExtensionProjectStatusChip status={row.status} /></TableCell>
                                        <TableCell>
                                            <Tooltip title="Visualizar detalhes do projeto">
                                                <IconButton size="small" onClick={() => navigate(`/extension-projects/${row.id}`)}>
                                                    <Icon>remove_red_eye</Icon>
                                                </IconButton>
                                            </Tooltip>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )
            ) : (
                isMobile ? (
                    <List>
                        {usersList?.items?.map(row => (
                            <>
                                <ListItem key={`${row.userId}${row.extensionProjectId}`} onClick={() => navigate(`/extension-projects/${row.extensionProject.id}`)} button>
                                    <ListItemText
                                        primary={row.user.name}
                                        secondary={`Projeto: ${row.extensionProject.name} | Horas: ${row.workload}`}
                                    />
                                    <ParticipationStatusChip status={row.status ?? 99} />
                                </ListItem>
                                <Divider />
                            </>
                        ))}
                    </List>
                ) : (
                    <TableContainer component={Paper} variant="outlined" sx={{ m: 1, width: 'auto' }}>
                        {loading && <LinearProgress variant="indeterminate" />}
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>ID</TableCell>
                                    <TableCell>Nome</TableCell>
                                    <TableCell>Matrícula</TableCell>
                                    <TableCell>Horas</TableCell>
                                    <TableCell>Projeto</TableCell>
                                    <TableCell>Status</TableCell>
                                    <TableCell>Ações</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {usersList?.items?.map(row => (
                                    <TableRow key={`${row.userId}${row.extensionProjectId}`}>
                                        <TableCell>{row.userId}</TableCell>
                                        <TableCell>{row.user.name}</TableCell>
                                        <TableCell>{row.user.registration}</TableCell>
                                        <TableCell>{row.workload}</TableCell>
                                        <TableCell>{row.extensionProject.name}</TableCell>
                                        <TableCell><ParticipationStatusChip status={row.status ?? 99} /></TableCell>
                                        <TableCell>
                                            <Tooltip title="Visualizar detalhes do projeto">
                                                <IconButton size="small" onClick={() => navigate(`/extension-projects/${row.extensionProject.id}`)}>
                                                    <Icon>remove_red_eye</Icon>
                                                </IconButton>
                                            </Tooltip>
                                        </TableCell>
                                    </TableRow>
                                ))}
                                {rowCount > 0 && (
                                    <TableRow>
                                        <TableCell colSpan={5} align="right">
                                            <Typography variant="body1">Total de horas abatidas:</Typography>
                                        </TableCell>
                                        <TableCell align="right"><strong>{usersList?.items?.filter(row => row.status === 3).reduce((sum, row) => sum + row.workload, 0)}</strong></TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )
            )}
        </BasePageLayout>
    );
}
