import { useEffect, useState } from "react";
import {
    Select,
    MenuItem,
    SelectProps,
    FormControl,
    InputLabel,
    OutlinedInput,
    FormHelperText,
    SelectChangeEvent
} from "@mui/material";
import { useField } from "@unform/core";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

interface Option {
    id: string | number;
    text: string;
}

type VSelectOneProps = SelectProps & {
    name: string;
    options: Option[];
    label: string;
};

export const VSelectOne: React.FC<VSelectOneProps> = ({
    name,
    options,
    label,
    ...rest
}) => {
    const { fieldName, registerField, error } = useField(name);
    const [selectedId, setSelectedId] = useState<string | number | null>(null);

    useEffect(() => {
        registerField({
            name: fieldName,
            getValue: () => selectedId,
            setValue: (_, newValue) => {
                setSelectedId(newValue || null);
            },
        });
    }, [registerField, fieldName, selectedId]);

    const handleChange = (event: SelectChangeEvent<unknown>) => {
        setSelectedId(event.target.value as string | number);
    };

    return (
        <FormControl fullWidth>
            <InputLabel>{label}</InputLabel>
            <Select
                value={selectedId || ""}
                onChange={handleChange}
                input={<OutlinedInput />}
                error={!!error}
                {...rest}
                MenuProps={MenuProps}
            >
                {options.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                        {option.text}
                    </MenuItem>
                ))}
            </Select>
            {error && <FormHelperText style={{ color: "red" }}>{error}</FormHelperText>}
        </FormControl>
    );
};
