import { useEffect, useState } from "react";
import { Checkbox, CheckboxProps, FormHelperText } from "@mui/material";
import { useField } from "@unform/core";

type VCheckboxProps = CheckboxProps & {
    name: string;
};

export const VCheckbox: React.FC<VCheckboxProps> = ({ name, ...rest }) => {
    const { fieldName, registerField, defaultValue, error, clearError } = useField(name);

    const [value, setValue] = useState(defaultValue || false);

    useEffect(() => {
        registerField({
            name: fieldName,
            getValue: () => value,
            setValue: (_, newValue) => setValue(newValue),
        });
    }, [registerField, fieldName, value]);

    return (
        <>
            <Checkbox
                {...rest}
                checked={value}
                onChange={(e) => {
                    setValue(e.target.checked);
                    rest.onChange?.(e, e.target.checked);
                }}
                onBlur={(e) => {
                    clearError();
                    rest.onBlur?.(e);
                }}
            />
            {error && <FormHelperText style={{ color: "red" }}>{error}</FormHelperText>}
        </>

    );
};
