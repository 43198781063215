import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

interface IConfirmDialogProps {
    open: boolean;
    dialogTitle: string;
    dialogDescription: string;
    onConfirm: () => void;
    onClose: () => void;
}


export const ConfirmDialog: React.FC<IConfirmDialogProps> = ({
    open = false,
    dialogTitle = '',
    dialogDescription = '',
    onConfirm,
    onClose
}) => {

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
        >
            <DialogTitle id="alert-dialog-title">
                {dialogTitle}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {dialogDescription}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => onClose()} variant='contained'>
                    Cancelar
                </Button>
                <Button onClick={onConfirm} autoFocus variant='contained'>
                    Confirmar
                </Button>
            </DialogActions>
        </Dialog>
    );
}
