import { Environment } from "../../../environment";
import { ICourseSearchResponse, ICourseResponse, ICoursePost } from "../../../interfaces";

import { Api } from "../axios-config"

const controller = "Course"

const search = async (name: string, rowsPerPage: number = Environment.ROWS_LIMIT10, page: number = 0): Promise<ICourseSearchResponse | Error> => {

    try {

        const { data } = await Api.get<ICourseSearchResponse>(`${controller}?Name=${name}&RowsPerPage=${rowsPerPage}&Page=${page}`);

        if (data) {
            return data;
        }

        return new Error("Erro ao buscar os cursos.");

    } catch (error) {
        console.error(error);

        return new Error((error as { message: string }).message || "Erro ao buscar os cursos.");
    }
}

const getById = async (id: number): Promise<ICourseResponse | Error> => {

    try {

        const { data } = await Api.get<ICourseResponse>(`${controller}/${id}`);

        if (data) {
            return data;
        }

        return new Error("Erro ao buscar o curso.");

    } catch (error) {
        console.error(error);

        return new Error((error as { message: string }).message || "Erro ao buscar o curso.");
    }

}

const getAll = async (): Promise<ICourseResponse[] | Error> => {

    try {

        const { data } = await Api.get<ICourseResponse[]>(`${controller}/GetAll`);

        if (data) {
            return data;
        }

        return new Error("Erro ao buscar os cursos.");

    } catch (error) {
        console.error(error);

        return new Error((error as { message: string }).message || "Erro ao buscar os cursos.");
    }

}

const create = async (course: ICoursePost): Promise<ICourseResponse | Error> => {

    try {

        const { data } = await Api.post<ICourseResponse>(`${controller}`, course);

        if (data) {
            return data;
        }

        return new Error("Erro ao criar o curso.");

    } catch (error) {
        console.error(error);

        return new Error((error as { message: string }).message || "Erro ao criar o curso.");
    }
}

const deleteById = async (id: number): Promise<void | Error> => {

    try {

        await Api.delete(`${controller}/${id}`);

    } catch (error) {
        console.error(error);
        return new Error((error as { message: string }).message || "Erro ao deletar o curso.");
    }
}

export const CourseService = {
    search,
    getById,
    getAll,
    create,
    deleteById,
};