import { useState } from "react";
import { Box, Button, Icon, Paper, Skeleton, useTheme } from "@mui/material";
import { ConfirmDialog } from "../confirmDialog/ConfirmDialog";

interface IApplyProjectToolbarProps {
    textButton?: string;
    textCancelButton?: string;
    showApplyButton?: boolean;
    showCancelButton?: boolean;
    showButtonLoading?: boolean;
    onClickButtonApply: () => void;
    onClickButtonCancel: () => void;
}

export const ApplyProjectToolbar: React.FC<IApplyProjectToolbarProps> = ({
    textButton = 'Candidatar-se',
    textCancelButton = 'Cancelar Candidatura',
    showApplyButton,
    showCancelButton,
    showButtonLoading,
    onClickButtonApply,
    onClickButtonCancel
}) => {

    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogCancelOpen, setDialogCancelOpen] = useState(false);

    const theme = useTheme();

    return (
        <Box
            gap={1}
            marginX={1}
            padding={1}
            paddingX={2}
            display="flex"
            alignItems="center"
            height={theme.spacing(6)}
            component={Paper}
        >

            <ConfirmDialog
                dialogTitle="Candidatar no Projeto"
                dialogDescription="Deseja candidatar neste projeto?"
                onClose={() => { setDialogOpen(false) }}
                onConfirm={() => {
                    setDialogOpen(false)
                    onClickButtonApply();
                }}
                open={dialogOpen}
            />

            <ConfirmDialog
                dialogTitle="Cancelar Candidatura"
                dialogDescription="Deseja cancelar sua candidatura?"
                onClose={() => { setDialogCancelOpen(false) }}
                onConfirm={() => {
                    setDialogCancelOpen(false)
                    onClickButtonCancel();
                }}
                open={dialogCancelOpen}
            />

            <Box
                flex={1}
                gap={1}
                display="flex" justifyContent="end"
            >

                {
                    !showButtonLoading && showApplyButton  &&
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<Icon>assignment_turned_in</Icon>}
                        onClick={() => setDialogOpen(true)}
                        disabled={showButtonLoading}
                    >
                        {textButton}
                    </Button>
                }

                {
                    !showButtonLoading && showCancelButton  &&
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<Icon>assignment_turned_in</Icon>}
                        onClick={() => setDialogCancelOpen(true)}
                    >
                        {textCancelButton}
                    </Button>
                }

                {
                    showButtonLoading &&
                    <Skeleton
                        width={180}
                        height={60}
                    />
                }

            </Box>

        </Box>
    );
}