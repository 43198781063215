import { useEffect, useMemo, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import {
    Icon, IconButton, LinearProgress, List, ListItem, ListItemText,
    Box, Tooltip, Pagination, Paper, Divider, useMediaQuery, useTheme,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TableRow
} from "@mui/material";

import { ExtensionProjectComputeHoursToolbar, ParticipationStatusChip, ConfirmDialog } from "../../shared/components";
import { useAuthContext } from "../../shared/contexts";
import { Environment } from "../../shared/environment";
import { IStudentExtensionProjectSearchResponse } from "../../shared/interfaces";
import { BasePageLayout } from "../../shared/layouts";
import { UserService } from "../../shared/services/api";

export const ExtensionProjectComputeHours = () => {
    const [searchParam, setSearchParams] = useSearchParams();
    const { userId } = useAuthContext();
    const [usersList, setUsersList] = useState<IStudentExtensionProjectSearchResponse>();
    const [loading, setLoading] = useState<boolean>(false);
    const [rowCount, setRowCount] = useState<number>(0);
    const [userIdSelected, setUserIdSelected] = useState<number>(0);
    const [extensionProjectIdSelected, setExtensionProjectIdSelected] = useState<number>(0);
    const [dialogApproveOpen, setDialogApproveOpen] = useState<boolean>(false);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const userName = useMemo(() => searchParam.get('userName') || '', [searchParam]);
    const registration = useMemo(() => searchParam.get('registration') || '', [searchParam]);
    const finalized = useMemo(() => searchParam.get('finalized') || "", [searchParam]);
    const listCourseId = useMemo(() => searchParam.get('listCourseId') || "", [searchParam]);
    const page = useMemo(() => Number(searchParam.get('page') || '1'), [searchParam]);

    const countRef = useRef(0);

    useEffect(() => {
        if (process.env.NODE_ENV === 'development') {
            if (countRef.current === 0) {
                countRef.current = 1;
                return;
            }
        }
        handleSearch();
    }, [page]);

    const handleSearch = () => {
        setLoading(true);
        UserService.getStudendStatusPendingDeductionOfHours(userName, registration, finalized === "true", listCourseId, page - 1)
            .then((result) => {
                if (!(result instanceof Error)) {
                    setUsersList(result);
                    setRowCount(result.rowsCount);
                }
            })
            .finally(() => setLoading(false));
    };

    const UpdateStatusToReportedWorkload = () => {
        setLoading(true);
        UserService.updateStatusToReportedWorkload({ userId: userIdSelected, extensionProjectId: extensionProjectIdSelected })
            .then((result) => {
                if (!(result instanceof Error)) {
                    handleSearch();
                }
            })
            .finally(() => setLoading(false));
    };

    return (
        <BasePageLayout
            title="Usuários"
            toolbar={(
                <ExtensionProjectComputeHoursToolbar
                    userName={userName}
                    registration={registration}
                    finalized={finalized}
                    listCourseIds={listCourseId}
                    showButton
                    onChangeUserName={text => setSearchParams({ userName: text, registration, finalized, listCourseId, page: page.toString() }, { replace: true })}
                    onChangeRegistration={text => setSearchParams({ userName, registration: text, finalized, listCourseId, page: page.toString() }, { replace: true })}
                    onChangeFinalized={text => setSearchParams({ userName, registration, finalized: text, listCourseId, page: page.toString() }, { replace: true })}
                    onChangeListCourseIds={cl => setSearchParams({ userName, registration, finalized, listCourseId: cl, page: page.toString() }, { replace: true })}
                    onClickSearch={() => handleSearch()}
                />
            )}
        >

            {loading && <LinearProgress variant="indeterminate" />}
            <TableContainer component={Paper} variant="outlined" sx={{ m: 1, width: 'auto' }}>
                {isMobile ? (
                    <List>
                        {usersList?.items?.map(row => (
                            <Box key={`${row.userId}${row.extensionProjectId}`}>
                                {/* Informações principais do usuário */}
                                <ListItem alignItems="flex-start">
                                    <ListItemText
                                        primary={row.user.name}
                                        secondary={(
                                            <>
                                                Matrícula: {row.user.registration} <br />
                                                Horas: {row.workload} <br />
                                                Projeto: {row.extensionProject.name}
                                                <ParticipationStatusChip status={row.status ?? 99} />
                                            </>
                                        )}
                                    />

                                </ListItem>

                                {/* Rodapé com as ações */}
                                <Box display="flex" justifyContent="flex-start" alignItems="center" sx={{ pl: 2, pb: 1, pt: 1 }} width="100%" flexWrap="wrap">
                                    {row.status === 2 && (
                                        <Tooltip title="Confirmar horas abatidas">
                                            <IconButton size="small" onClick={() => {
                                                setUserIdSelected(row.userId);
                                                setExtensionProjectIdSelected(row.extensionProjectId);
                                                setDialogApproveOpen(true);
                                            }}>
                                                <Icon>check</Icon>
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                </Box>
                                <Divider />
                            </Box>
                        ))}
                        {rowCount === 0 && !loading && "Nenhum registro encontrado"}
                    </List>
                ) : (
                    <>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>ID</TableCell>
                                    <TableCell>Nome</TableCell>
                                    <TableCell>Matrícula</TableCell>
                                    <TableCell>Horas</TableCell>
                                    <TableCell>Projeto</TableCell>
                                    <TableCell>Status</TableCell>
                                    <TableCell>Ações</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {usersList?.items?.map(row => (
                                    <TableRow key={`${row.userId}${row.extensionProjectId}`}>
                                        <TableCell>{row.userId}</TableCell>
                                        <TableCell>{row.user.name}</TableCell>
                                        <TableCell>{row.user.registration}</TableCell>
                                        <TableCell>{row.workload}</TableCell>
                                        <TableCell>{row.extensionProject.name}</TableCell>
                                        <TableCell><ParticipationStatusChip status={row.status ?? 99} /></TableCell>
                                        <TableCell>
                                            {row.status === 2 && (
                                                <IconButton size="small" onClick={() => {
                                                    setUserIdSelected(row.userId);
                                                    setExtensionProjectIdSelected(row.extensionProjectId);
                                                    setDialogApproveOpen(true);
                                                }}>
                                                    <Tooltip title="Confirmar horas abatidas">
                                                        <Icon>check</Icon>
                                                    </Tooltip>
                                                </IconButton>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>

                            {rowCount === 0 && !loading && <caption>Nenhum registro encontrado</caption>}
                            {loading && <LinearProgress variant="indeterminate" />}
                            {rowCount > 0 && rowCount > Environment.ROWS_LIMIT10 && (
                                <TableFooter>
                                    <TableRow>
                                        <TableCell colSpan={3}>
                                            <Pagination
                                                page={page}
                                                count={Math.ceil(rowCount / Environment.ROWS_LIMIT10)}
                                                onChange={(_, newPage) => {
                                                    setSearchParams({ userName, registration, finalized, page: newPage.toString() }, { replace: true });
                                                }}
                                            />
                                        </TableCell>
                                    </TableRow>
                                </TableFooter>
                            )}
                        </Table>
                    </>
                )}

            </TableContainer>

            <ConfirmDialog
                dialogTitle="Computar horas"
                dialogDescription="Deseja confirmar as horas deste usuário?"
                onClose={() => setDialogApproveOpen(false)}
                onConfirm={() => {
                    setDialogApproveOpen(false);
                    UpdateStatusToReportedWorkload();
                }}
                open={dialogApproveOpen}
            />
        </BasePageLayout>
    );
};
