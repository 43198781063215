import { useEffect, useMemo, useRef, useState } from "react";
import {
    Icon, IconButton, LinearProgress, Pagination, Paper,
    Table, TableBody, TableCell, TableContainer, TableFooter, TableHead,
    TableRow, Tooltip, Typography, Accordion, AccordionSummary, AccordionDetails,
    List, ListItem, ListItemText, Box, useMediaQuery, useTheme,
    FormHelperText,
    TextareaAutosize,
    Divider
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import { ConfirmDialog, ConfirmDialogForm, ExtensionProjectStatusChip } from "../../shared/components";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAuthContext } from "../../shared/contexts";
import { Environment } from "../../shared/environment";
import { IDisapproveExtensionProject, ICourse_ExtensionProjectSearchResponse } from "../../shared/interfaces";
import { BasePageLayout } from "../../shared/layouts";
import { ExtensionProjectService } from "../../shared/services/api";
import { enqueueSnackbar } from "notistack";

export const ExtensionProjectsApproval = () => {
    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const { userId } = useAuthContext();

    const [loading, setLoading] = useState<boolean>(false);
    const [rowCount, setRowCount] = useState<number>(0);
    const [courseExtensionProjectList, setCourseExtensionProjectList] = useState<ICourse_ExtensionProjectSearchResponse>();
    const [extensionProjectId, setExtensionProjectId] = useState<number>(0);
    const [courseId, setCourseId] = useState<number>(0);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogFormOpen, setDialogFormOpen] = useState(false);
    const [disaproveReason, setDisaproveReason] = useState("");
    const [erroDisaproveReason, setErroDisaproveReason] = useState<string>();
    const [disaproveStatus, setDisaproveStatus] = useState<number>(2 | 3);

    const [searchParam, setSearchParams] = useSearchParams();

    const page = useMemo(() => {
        return Number(searchParam.get('page') || '1');
    }, [searchParam]);

    const countRef = useRef(0);

    useEffect(() => {
        if (process.env.NODE_ENV === 'development') {
            if (countRef.current === 0) {
                countRef.current = 1;
                return;
            }
        }
        handleSearch();
    }, [page]);

    const handleSearch = () => {
        setLoading(true);
        ExtensionProjectService.getExtensionProjectPendingApproval(userId, page - 1)
            .then((result) => {
                if (!(result instanceof Error)) {
                    setCourseExtensionProjectList(result);
                    setRowCount(result.rowsCount);
                }
            })
            .finally(() => setLoading(false));
    };

    const handleApproveExtensionProject = () => {
        setLoading(true);
        setDialogOpen(false);

        ExtensionProjectService.approveExtensionProject({
            extensionProjectId: extensionProjectId,
            courseId: courseId,
        }).then((result) => {
            if (!(result instanceof Error)) {
                enqueueSnackbar(
                    <Typography>Projeto aprovado com sucesso</Typography>,
                    { variant: 'success' }
                );
            }
            handleSearch();
        }).finally(() => setLoading(false));
    };

    const handleDisapproveExtensionProject = (status: number) => {
        const request: IDisapproveExtensionProject = {
            extensionProjectId: extensionProjectId,
            status: status,
            courseId: courseId,
            reason: disaproveReason
        };

        ExtensionProjectService.disapproveExtensionProject(request).then((result) => {
            if (!(result instanceof Error)) {
                enqueueSnackbar(
                    <Typography>Projeto reprovado com sucesso</Typography>,
                    { variant: 'success' }
                );
                handleSearch();
                setDialogFormOpen(false);
            }
        }).finally(() => setLoading(false));
    };

    return (
        <BasePageLayout title="Aprovação">
            <ConfirmDialog
                dialogTitle="Aprovar Projeto"
                dialogDescription="Deseja aprovar este projeto?"
                onClose={() => { setDialogOpen(false); }}
                onConfirm={handleApproveExtensionProject}
                open={dialogOpen}
            />

            <ConfirmDialogForm
                dialogTitle="Reprovar Projeto"
                onClose={() => { setDialogFormOpen(false); }}
                onConfirm={() => handleDisapproveExtensionProject(disaproveStatus)}
                open={dialogFormOpen}
            >
                <TextareaAutosize
                    aria-label="empty textarea"
                    placeholder="Motivo"
                    style={{
                        width: isMobile ? 250 : 450, // Ocupa 100% da largura disponível tanto em mobile quanto em desktop
                        fontFamily: "roboto",
                        height: isMobile ? 200 : 300, // Altura maior para desktop
                        maxHeight: isMobile ? '30vh' : '20vh', // Limite de altura para não extrapolar o dialog
                        overflow: 'auto', // Evita scroll horizontal
                        padding: '8px',
                        borderRadius: '4px',
                        border: '1px solid #ccc',
                        resize: 'none' // Desabilita o redimensionamento manual
                    }}
                    onKeyDown={() => setErroDisaproveReason(undefined)}
                    onChange={(e) => setDisaproveReason(e.target.value)}
                    minRows={5}
                />
                {erroDisaproveReason && <FormHelperText style={{ color: "red" }}>{erroDisaproveReason}</FormHelperText>}
            </ConfirmDialogForm>
            <TableContainer component={Paper} variant="outlined" sx={{ m: 1, width: 'auto' }}>
                {isMobile ? (
                    <List>
                        {courseExtensionProjectList?.items?.map(row => (
                            <Box key={`${row.courseId}${row.extensionProjectId}`} >
                                {/* Informações principais do projeto */}
                                <ListItem alignItems="flex-start">
                                    <ListItemText
                                        primary={
                                            <Typography variant="h6">
                                                {row.extensionProject.name}
                                            </Typography>
                                        }
                                        secondary={
                                            <>
                                                <Typography variant="body2">
                                                    Professor: {row.extensionProject.responsibleUser?.name}
                                                </Typography>
                                                <Typography variant="body2">
                                                    Curso: {row.course?.acronym}
                                                </Typography>
                                                <ExtensionProjectStatusChip status={row.status} />
                                            </>
                                        }
                                    />

                                </ListItem>

                                {/* Rodapé com as ações */}
                                <Box
                                    display="flex"
                                    justifyContent="flex-start"
                                    alignItems="center"
                                    sx={{ pl: 2, pb: 1, pt: 1 }}
                                    width="100%"
                                    flexWrap="wrap"
                                >
                                    <Tooltip title="Visualizar projeto">
                                        <IconButton size="small" onClick={() => navigate(`/extension-projects/${row.extensionProjectId}`)}>
                                            <Icon>remove_red_eye</Icon>
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Aprovar projeto">
                                        <IconButton
                                            size="small"
                                            onClick={() => {
                                                setDialogOpen(true);
                                                setExtensionProjectId(row.extensionProjectId);
                                                setCourseId(row.courseId);
                                            }}
                                            color="success"
                                            disabled={loading}
                                        >
                                            <Icon>check_circle</Icon>
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Reprovar para revisão">
                                        <IconButton
                                            size="small"
                                            onClick={() => {
                                                setDialogFormOpen(true);
                                                setDisaproveStatus(2);
                                                setExtensionProjectId(row.extensionProjectId);
                                                setCourseId(row.courseId);
                                            }}
                                            color="warning"
                                            disabled={loading}
                                        >
                                            <Icon>do_not_disturb_alt</Icon>
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Reprovar definitivamente">
                                        <IconButton
                                            size="small"
                                            onClick={() => {
                                                setDialogFormOpen(true);
                                                setDisaproveStatus(3);
                                                setExtensionProjectId(row.extensionProjectId);
                                                setCourseId(row.courseId);
                                            }}
                                            color="error"
                                            disabled={loading}
                                        >
                                            <Icon>cancel</Icon>
                                        </IconButton>
                                    </Tooltip>
                                </Box>
                                <Divider />
                            </Box>

                        ))}
                    </List>
                ) : (

                    <>
                        {loading && <LinearProgress variant="indeterminate" />}
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Nome</TableCell>
                                    <TableCell>Professor Responsável</TableCell>
                                    <TableCell>Curso</TableCell>
                                    <TableCell>Status</TableCell>
                                    <TableCell>Ações</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {courseExtensionProjectList?.items?.map(row => (
                                    <TableRow key={`${row.courseId}${row.extensionProjectId}`}>
                                        <TableCell>{row.extensionProject.name}</TableCell>
                                        <TableCell>{row.extensionProject.responsibleUser?.name}</TableCell>
                                        <TableCell>{`${row.course?.acronym} - ${row.course?.name}`}</TableCell>
                                        <TableCell><ExtensionProjectStatusChip status={row.status} /></TableCell>
                                        <TableCell>
                                            <Box>
                                                <Tooltip title="Visualizar projeto">
                                                    <IconButton size="small" onClick={() => navigate(`/extension-projects/${row.extensionProjectId}`)}>
                                                        <Icon>remove_red_eye</Icon>
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title="Aprovar projeto">
                                                    <IconButton
                                                        size="small"
                                                        onClick={() => {
                                                            setDialogOpen(true);
                                                            setExtensionProjectId(row.extensionProjectId);
                                                            setCourseId(row.courseId);
                                                        }}
                                                        color="success"
                                                        disabled={loading}
                                                    >
                                                        <Icon>check_circle</Icon>
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title="Reprovar para revisão">
                                                    <IconButton
                                                        size="small"
                                                        onClick={() => {
                                                            setDialogFormOpen(true);
                                                            setDisaproveStatus(2);
                                                            setExtensionProjectId(row.extensionProjectId);
                                                            setCourseId(row.courseId);
                                                        }}
                                                        color="warning"
                                                        disabled={loading}
                                                    >
                                                        <Icon>do_not_disturb_alt</Icon>
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title="Reprovar definivamente">
                                                    <IconButton
                                                        size="small"
                                                        onClick={() => {
                                                            setDialogFormOpen(true);
                                                            setDisaproveStatus(3);
                                                            setExtensionProjectId(row.extensionProjectId);
                                                            setCourseId(row.courseId);
                                                        }}
                                                        color="error"
                                                        disabled={loading}
                                                    >
                                                        <Icon>cancel</Icon>
                                                    </IconButton>
                                                </Tooltip>
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </>
                )}
                {loading && <LinearProgress variant="indeterminate" />}
                {rowCount > 0 && rowCount > Environment.ROWS_LIMIT10 && (
                    <TableFooter>
                        <TableRow>
                            <TableCell colSpan={3}>
                                <Pagination
                                    page={page}
                                    count={Math.ceil(rowCount / Environment.ROWS_LIMIT10)}
                                    onChange={(_, newPage) => setSearchParams({ page: newPage.toString() }, { replace: true })}
                                />
                            </TableCell>
                        </TableRow>
                    </TableFooter>

                )}
            </TableContainer>


        </BasePageLayout>
    );
}
