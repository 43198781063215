import { Chip } from '@mui/material';

interface ExtensionProjectStatusChipProps {
    status: number;
}

export const ExtensionProjectStatusChip: React.FC<ExtensionProjectStatusChipProps> = ({ status }) => {

    const getStatusLabel = (status: number): {
        label: string;
        color:
        'primary' |
        'secondary' |
        'warning' |
        'error' |
        'success' |
        'info'
    } => {

        switch (status) {
            case 0:
                return { label: 'Aprovação Pendente', color: 'warning' };
            case 1:
                return { label: 'Aprovado', color: 'success' };
            case 2:
                return { label: 'Reprovado para Revisão', color: 'secondary' };
            case 3:
                return { label: 'Reprovado Permanente', color: 'error' };
            case 4:
                return { label: 'Em andamento', color: 'success' };
            case 5:
                return { label: 'Pausado', color: 'warning' };
            case 6:
                return { label: 'Concluído', color: 'primary' };
            case 7:
                    return { label: 'Removido', color: 'error' };
            default:
                return { label: 'Desconhecido', color: 'secondary' };
        }

    };

    const { label, color } = getStatusLabel(status);

    return (
        <Chip label={label} color={color} size="small"/>
    );
};

