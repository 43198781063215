import { createTheme } from '@mui/material';

export const LightTheme = createTheme({
    palette: {
        primary: {
            main: '#ab003f',
            dark: '#89003a',
            light: '#d31343',
            contrastText: '#ffffff',
        },
        secondary: {
            main: '#9b2658',
            dark: '#771f4d',
            light: '#e891b2',
            contrastText: '#ffffff',
        },
        background: {
            default: '#f7f6f3',
            paper: '#ffffff',
        }
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: `
                ::-webkit-scrollbar {
                    width: 5px;
                    height: 5px;
                }
                ::-webkit-scrollbar-track {
                    background: #f1f1f1;
                }
                ::-webkit-scrollbar-thumb {
                    background-color: #888;
                    border-radius: 10px;
                    border: 2px solid #f1f1f1;
                }
                ::-webkit-scrollbar-thumb:hover {
                    background-color: #555;
                }
                html {
                    scrollbar-width: thin;
                    scrollbar-color: #888 #f1f1f1;
                }
            `,
        },
    },
});
