import { AxiosError } from "axios";
import { enqueueSnackbar } from "notistack";
import { Typography } from "@mui/material";
import { Environment } from "../../../../environment";

interface ValidationErrorResponse {
    errors?: Record<string, string[]>;
}

export const errorInterceptor = (error: AxiosError<ValidationErrorResponse>) => {

    // Verifica se o erro é de rede ou uma ausência de resposta
    if (!error.response) {
        if (error.message === "Network Error" && !error.config?.headers?.Authorization) {
            enqueueSnackbar(
                <Typography>
                    Erro de conexão.
                </Typography>, 
                { variant: 'error' }
            );
            return Promise.reject(new Error("Erro de conexão."));
        } else if (error.code === "ERR_NETWORK" && error.config?.headers?.Authorization) {
            localStorage.removeItem(Environment.LOCAL_STORAGE_KEY_ACCESS_TOKEN);

            if (window.location.pathname !== "/") {
                enqueueSnackbar(
                    <Typography>
                        Falha de autenticação
                    </Typography>, 
                    { variant: 'error' }
                );

                setTimeout(() => {
                    window.location.pathname = "/";
                }, 2000);
            }
            return Promise.reject(new Error("Erro de conexão."));
        }
    }

    // Erros de resposta HTTP
    if (error.response?.status === 401) {
        localStorage.removeItem(Environment.LOCAL_STORAGE_KEY_ACCESS_TOKEN);

        if (window.location.pathname !== "/") {
            enqueueSnackbar(
                <Typography>
                    Falha de autenticação
                </Typography>, 
                { variant: 'error' }
            );

            setTimeout(() => {
                window.location.pathname = "/";
            }, 2000);
        }
        return Promise.reject(error);
    } 
    
    if (error.response?.status === 400 && error.response.data?.errors) {
        const validationErrors = error.response.data.errors;
        const friendlyMessages = Object.values(validationErrors).flat().join('\n');

        enqueueSnackbar(
            <Typography style={{ whiteSpace: 'pre-line' }}>
                {friendlyMessages}
            </Typography>, 
            { variant: 'error' }
        );

        return Promise.reject(error);
    } 

    // Outras respostas de erro
    enqueueSnackbar(
        <Typography>
            {error.request?.response}
        </Typography>, 
        { variant: 'error' }
    );

    return Promise.reject(error);
}


export const publicErrorInterceptor = (error: AxiosError) => {

    if (error.response?.status !== 401 && error.message === "Network Error") {

        enqueueSnackbar(
            <Typography>
                Erro de conexão.
            </Typography>
            , { variant: 'error' });

        return Promise.reject(new Error("Erro de conexão."));
    }else if (error.response?.status === 401) {

        localStorage.removeItem(Environment.LOCAL_STORAGE_KEY_ACCESS_TOKEN);

        enqueueSnackbar(
            <Typography>
                Falha de autenticação
            </Typography>
            , { variant: 'error' });

        setTimeout(() => {
            window.location.pathname = "/";
        }, 2000);

    } else {
        enqueueSnackbar(
            <Typography>
                {error.request?.response}
            </Typography>
            , { variant: 'error' });
    }

    return Promise.reject(error);
}
