

export const Environment = {
    ROWS_LIMIT5: 5,
    ROWS_LIMIT10: 10,
    SEARCH_INPUT: "Pesquisar...",
    LIST_EMPTY: "Nenhum registro encontrado.",
    BASE_URL: "https://geeu-api-hmg-ypsy6.ondigitalocean.app/api/",
    //BASE_URL: "https://localhost:7290/api/",
    LOCAL_STORAGE_KEY_ACCESS_TOKEN: "APP_ACCESS_TOKEN",
    LOCAL_STORAGE_KEY_EXPIRATION: "EXPIRATION",
    THEME: "theme",
    ROLE_ADMIN: "ADMIN",
    ROLE_ALUNO: "ALUNO",
    ROLE_PROFESSOR: "PROFESSOR",
    ROLE_GESTOR: "GESTOR",
    ROLE_COLEGIADO: "COLEGIADO",
};