import { Box, Button, Divider, Icon, Paper, Skeleton, Theme, Typography, useMediaQuery, useTheme } from "@mui/material"

interface IDetailToolbarProps {
    textButtonAdd?: string;

    showButtonAdd?: boolean;
    showButtonBack?: boolean;
    showButtonDelete?: boolean;
    showButtonSave?: boolean;
    showButtonSaveAndBack?: boolean;

    showButtonAddLoading?: boolean;
    showButtonBackLoading?: boolean;
    showButtonDeleteLoading?: boolean;
    showButtonSaveLoading?: boolean;
    showButtonSaveAndBackLoading?: boolean;

    onClickButtonAdd?: () => void;
    onClickButtonBack?: () => void;
    onClickButtonDelete?: () => void;
    onClickButtonSave?: () => void;
    onClickButtonSaveAndBack?: () => void;
}

export const DetailToolbar: React.FC<IDetailToolbarProps> = ({
    textButtonAdd = 'Novo',

    showButtonAdd = true,
    showButtonBack = true,
    showButtonDelete = true,
    showButtonSave = true,
    showButtonSaveAndBack = false,

    showButtonAddLoading = false,
    showButtonBackLoading = false,
    showButtonDeleteLoading = false,
    showButtonSaveLoading = false,
    showButtonSaveAndBackLoading = false,

    onClickButtonAdd,
    onClickButtonBack,
    onClickButtonDelete,
    onClickButtonSave,
    onClickButtonSaveAndBack
}) => {

    const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
    const theme = useTheme();

    return (
        <Box
            gap={1}
            marginX={1}
            padding={1}
            paddingX={2}
            display="flex"
            alignItems="center"
            height={theme.spacing(6)}
            component={Paper}
        >
            {
                showButtonSave &&
                !showButtonSaveLoading &&
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<Icon>save</Icon>}
                    onClick={onClickButtonSave}
                >
                    <Typography variant="button" whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
                        Salvar
                    </Typography>
                </Button>
            }

            {
                showButtonSaveLoading &&
                <Skeleton
                    width={180}
                    height={60}
                />
            }

            {
                showButtonSaveAndBack &&
                !showButtonSaveAndBackLoading &&
                !smDown && !mdDown &&
                <Button
                    variant="outlined"
                    color="primary"
                    startIcon={<Icon>save</Icon>}
                    onClick={onClickButtonSaveAndBack}
                >
                    <Typography variant="button" whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
                        Salvar e voltar
                    </Typography>
                </Button>
            }

            {
                showButtonSaveAndBackLoading &&
                !smDown && !mdDown &&
                <Skeleton
                    width={110}
                    height={60}
                />
            }

            {
                showButtonDelete &&
                !showButtonDeleteLoading &&
                <Button
                    variant="outlined"
                    color="primary"
                    startIcon={<Icon>delete</Icon>}
                    onClick={onClickButtonDelete}
                >
                    <Typography variant="button" whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
                        Apagar
                    </Typography>
                </Button>
            }

            {
                showButtonDeleteLoading &&
                <Skeleton
                    width={110}
                    height={60}
                />
            }

            {
                showButtonAdd &&
                !showButtonAddLoading &&
                !smDown &&
                <Button
                    variant="outlined"
                    color="primary"
                    startIcon={<Icon>add</Icon>}
                    onClick={onClickButtonAdd}
                >
                    <Typography variant="button" whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
                        {textButtonAdd}
                    </Typography>
                </Button>
            }

            {
                showButtonAddLoading &&
                !smDown &&
                <Skeleton
                    width={110}
                    height={60}
                />
            }

            {
                showButtonBack &&
                (showButtonAdd || showButtonDelete || showButtonSave || showButtonSaveAndBack) &&
                <Divider variant="middle" orientation="vertical" />
            }

            {
                showButtonBack &&
                !showButtonBackLoading &&
                <Button
                    variant="outlined"
                    color="primary"
                    startIcon={<Icon>arrow_back</Icon>}
                    onClick={onClickButtonBack}
                >
                    <Typography variant="button" whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
                        Voltar
                    </Typography>
                </Button>
            }

            {
                showButtonBackLoading &&
                <Skeleton
                    width={110}
                    height={60}
                />
            }

        </Box>
    )
}