import { useEffect, useState } from "react";
import { FormHelperText, TextareaAutosizeProps, styled, InputBaseProps, Box, Typography } from "@mui/material";
import { useField } from "@unform/core";
import { TextareaAutosize as BaseTextareaAutosize } from '@mui/material';

const StyledTextareaAutosize = styled(BaseTextareaAutosize)(
  ({ theme }) => `
      width: 100%;
      font-family: ${theme.typography.fontFamily};
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;
      padding: 16.5px 14px;
      border-radius: ${theme.shape.borderRadius}px;
      color: ${theme.palette.text.primary};
      background-color: ${theme.palette.background.paper};
      border: 1px solid ${theme.palette.divider};
      
      &:hover {
        border-color: ${theme.palette.text.primary};
      }
      
      &:focus {
        border-color: ${theme.palette.primary.main};
        box-shadow: ${theme.shadows[1]};
      }
      
      &:focus-visible {
        outline: 0;
      }

      &.Mui-error {
        border-color: ${theme.palette.error.main};
      }
  `,
);

type TVTextareaProps = InputBaseProps & TextareaAutosizeProps & {
  name: string;
  maxLength?: number; // Limite máximo de caracteres
}

export const VTextarea: React.FC<TVTextareaProps> = ({ name, maxLength = 500, ...rest }) => {
  const { fieldName, registerField, defaultValue, error, clearError } = useField(name);
  const [value, setValue] = useState(defaultValue || '');

  useEffect(() => {
    registerField({
      name: fieldName,
      getValue: () => value,
      setValue: (_, newValue) => setValue(newValue),
      clearValue: () => setValue(''), // Limpa o valor do campo quando o form é resetado
    });
  }, [registerField, fieldName, value]);

  return (
    <>
      <StyledTextareaAutosize
        {...rest}
        value={value}
        maxLength={maxLength} // Define o limite máximo de caracteres
        onChange={(e) => {
          setValue(e.target.value);
          rest.onChange?.(e);
          error && clearError(); // Limpa o erro quando há uma alteração
        }}
        className={error ? 'Mui-error' : ''}
        aria-errormessage={error}
      />
      <Box display="flex" justifyContent="space-between" alignItems="center">
        {error && <FormHelperText error>{error}</FormHelperText>}
        <Typography variant="body2" color="textSecondary">
          {`${value?.length ?? 0}/${maxLength}`}
        </Typography>
      </Box>
    </>
  );
};
