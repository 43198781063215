import * as React from 'react';
import { ReactNode } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

interface IConfirmDialogFormProps {
    open: boolean;
    dialogTitle: string;
    onConfirm: () => void;
    onClose: () => void;
    children: ReactNode;
}

export const ConfirmDialogForm: React.FC<IConfirmDialogFormProps> = ({
    open = false,
    dialogTitle = '',
    onConfirm,
    onClose,
    children
}) => {

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >

            <DialogTitle id="alert-dialog-title">
                {dialogTitle}
            </DialogTitle>

            <DialogContent>
                {children}
            </DialogContent>

            <DialogActions>
                <Button onClick={() => onClose()} variant='contained'>
                    Cancelar
                </Button>
                <Button onClick={onConfirm} autoFocus variant='contained'>
                    Confirmar
                </Button>
            </DialogActions>

        </Dialog>
    );
}
