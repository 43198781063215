import { Environment } from "../../../environment";
import { IDepartmentSearchResponse, IDepartmentPost, IDepartmentResponse } from "../../../interfaces";
import { Api } from "../axios-config";

const controller = "Department"

const search = async (name: string, rowsPerPage: number = Environment.ROWS_LIMIT10, page: number = 0): Promise<IDepartmentSearchResponse | Error> => {

    try {

        const { data } = await Api.get<IDepartmentSearchResponse>(`${controller}?Name=${name}&RowsPerPage=${rowsPerPage}&Page=${page}`);

        if (data) {
            return data;
        }

        return new Error("Erro ao buscar os departamentos.");

    } catch (error) {
        console.error(error);

        return new Error((error as { message: string }).message || "Erro ao buscar os departamentos.");
    }
}

const create = async (department: IDepartmentPost): Promise<IDepartmentResponse | Error> => {

    try {

        const { data } = await Api.post<IDepartmentResponse>(`${controller}`, department);
        
        if (data) {
            return data;
        }

        return new Error("Erro ao criar o departamento.");

    } catch (error) {
        console.error(error);

        return new Error((error as { message: string }).message || "Erro ao criar o departamento.");
    }
}

const deleteById = async (id: number): Promise<void | Error> => {

    try {

        await Api.delete(`${controller}/${id}`);

    } catch (error) {
        console.error(error);
        return new Error((error as { message: string }).message || "Erro ao deletar o departamento.");
    }
}

const getAll = async (): Promise<IDepartmentResponse[] | Error> => {

    try {

        const { data } = await Api.get<IDepartmentResponse[]>(`${controller}/GetAll`);

        if (data) {
            return data;
        }

        return new Error("Erro ao buscar os departamentos.");

    } catch (error) {
        console.error(error);

        return new Error((error as { message: string }).message || "Erro ao buscar os departamentos.");
    }

}

export const DepartmentService = {
    search,
    create,
    deleteById,
    getAll
};