import { useState } from "react";
import { Box, Button, Icon, Paper, Skeleton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ConfirmDialog } from "../confirmDialog/ConfirmDialog";

interface IResponsibleUserToolbarProps {
    extensionProjectId: number;
    canEdit: boolean;
    canFinishProject: boolean;
    openForApplcation: boolean;
    loading: boolean;
    finishProject: () => void;
    openOrCloseApplication: () => void;
}

export const ResponsibleUserToolbar: React.FC<IResponsibleUserToolbarProps> = ({
    extensionProjectId,
    canEdit,
    canFinishProject,
    openForApplcation,
    loading = false,
    finishProject,
    openOrCloseApplication
}) => {

    const [dialogFinishOpen, setDialogFinishOpen] = useState(false);
    const [dialogApplicationOpen, setDialogApplicationOpen] = useState(false);

    const navigate = useNavigate();

    return (
        <Box
            gap={1}
            marginX={1}
            padding={1}
            paddingX={2}
            display="flex"
            alignItems="center"
            component={Paper}
            flexWrap="wrap"
        >

            <ConfirmDialog
                dialogTitle="Finalizar Projeto"
                dialogDescription="Deseja finalizar este projeto?"
                onClose={() => { setDialogFinishOpen(false) }}
                onConfirm={() => {
                    setDialogFinishOpen(false);
                    finishProject();
                }}
                open={dialogFinishOpen}
            />

            <ConfirmDialog
                dialogTitle={openForApplcation ? "Fechar Candidaturas" : "Abrir Candidaturas"}
                dialogDescription={openForApplcation ? "Deseja fechar as candidaturas?" : "Deseja abrir as candidaturas?"}
                onClose={() => { setDialogApplicationOpen(false) }}
                onConfirm={() => {
                    setDialogApplicationOpen(false);
                    openOrCloseApplication();
                }}
                open={dialogApplicationOpen}
            />

            <Box
                flex={1}
                gap={1}
                display="flex" justifyContent="end"
            >
                {
                    !loading &&
                    canEdit &&
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<Icon>edit</Icon>}
                        onClick={() => navigate("/extension-projects/new/" + extensionProjectId)}
                        size="small"
                    >
                        Editar
                    </Button>
                }

                {
                    !loading &&
                    canFinishProject &&
                    openForApplcation &&
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<Icon>lock</Icon>}
                        onClick={() => setDialogApplicationOpen(true)}
                        size="small"
                    >
                        Fechar candidaturas
                    </Button>
                }

                {
                    !loading &&
                    canFinishProject &&
                    !openForApplcation &&
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<Icon>lock_open</Icon>}
                        onClick={() => setDialogApplicationOpen(true)}
                        size="small"
                    >
                        Abrir candidaturas
                    </Button>
                }

                {
                    !loading &&
                    canFinishProject &&
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<Icon>done</Icon>}
                        onClick={() => setDialogFinishOpen(true)}
                        size="small"
                    >
                        Concluir Projeto
                    </Button>
                }

                {
                    loading &&
                    <Skeleton
                        width={180}
                        height={60}
                    />
                }

                {
                    loading &&
                    <Skeleton
                        width={180}
                        height={60}
                    />
                }

                {
                    loading &&
                    <Skeleton
                        width={180}
                        height={60}
                    />
                }

            </Box>

        </Box>
    );
}