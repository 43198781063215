import {
    ReactNode, createContext, useCallback,
    useContext, useEffect, useMemo, useState
} from "react";
import { jwtDecode } from "jwt-decode";

import { Environment } from "../environment";
import { ILoginPost } from "../interfaces";
import { UserService } from "../services/api";

interface IAuthContextData {
    userId: number;
    name: string;
    email: string;
    roles: string[];
    courses: string[] | string;
    expiration?: string;
    optInLGPD: boolean;
    isAuthenticated: boolean;
    logout: () => void;
    login: (data: ILoginPost) => Promise<string | Error | void>;
    updateOptInLGPD: () => void;
}

const AuthContext = createContext({} as IAuthContextData);

interface IAuthProviderProps {
    children: ReactNode
}

export const AuthProvider: React.FC<IAuthProviderProps> = ({ children }) => {

    const [accessToken, setAccessToken] = useState<string>();
    const [id, setId] = useState<number>(0);
    const [name, setName] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [roles, setRoles] = useState<string[]>([]);
    const [courses, setCourses] = useState<string[]>([]);
    const [expiration, setExpiration] = useState<string>();
    const [optInLGPD, setOptInLGPD] = useState<boolean>(false);

    useEffect(() => {
        const accessToken = localStorage.getItem(Environment.LOCAL_STORAGE_KEY_ACCESS_TOKEN);
        const expiration = localStorage.getItem(Environment.LOCAL_STORAGE_KEY_EXPIRATION)?.replace(/^"(.*)"$/, '$1');
    
        if (accessToken && expiration) {
            const expirationDate = new Date(expiration);
            const currentDate = new Date();
    
            if (expirationDate <= currentDate) {
                // Token is expired, remove it from localStorage
                localStorage.removeItem(Environment.LOCAL_STORAGE_KEY_ACCESS_TOKEN);
                localStorage.removeItem(Environment.LOCAL_STORAGE_KEY_EXPIRATION);
                setAccessToken(undefined);
            } else {
                // Token is still valid, set the state with its values
                const decodedToken = jwtDecode(accessToken) as any;
                setAccessToken(JSON.parse(accessToken));
                setId(decodedToken['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier']);
                setEmail(decodedToken['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress']);
                setName(decodedToken['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name']);
                setRoles(decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']);
                setOptInLGPD(decodedToken['OptInLGPD'] === "False" ? false : true);
                setExpiration(expiration);
            }
        } else {
            setAccessToken(undefined);
        }
    }, []);


    const handleLogin = useCallback(async (data: ILoginPost) => {

        const result = await UserService.authorize(data);

        if (result instanceof Error) {
            return result;
        }
        else {
            localStorage.setItem(Environment.LOCAL_STORAGE_KEY_ACCESS_TOKEN, JSON.stringify(result.token));
            localStorage.setItem(Environment.LOCAL_STORAGE_KEY_EXPIRATION, JSON.stringify(result.expiration));

            setAccessToken(result.token);

            var decodedToken = jwtDecode(result.token) as any;

            setId(decodedToken['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier']);
            setEmail(decodedToken['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress']);
            setName(decodedToken['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name']);
            setRoles(decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']);
            setCourses(decodedToken['CourseId']);
            setOptInLGPD(decodedToken['OptInLGPD'] === "False" ? false : true);
            setExpiration(result.expiration);
        }

    }, []);

    const handleLogout = useCallback(() => {
        localStorage.removeItem(Environment.LOCAL_STORAGE_KEY_ACCESS_TOKEN);
        localStorage.removeItem(Environment.LOCAL_STORAGE_KEY_EXPIRATION);
        setAccessToken(undefined);
        setExpiration(undefined);
    }, []);

    const handleUpdateOptInLGPD = useCallback(() => {

        setOptInLGPD(true);

    }, []);

    const isAuthenticated = useMemo(() => !!accessToken, [accessToken]);
    const userId = useMemo(() => id, [id]);
    const userEmail = useMemo(() => email, [email]);
    const userName = useMemo(() => name, [name]);
    const userExpiration = useMemo(() => expiration, [expiration]);
    const userRoles = useMemo(() => roles, [roles]);
    const userCourses = useMemo(() => courses, [courses]);
    const userOptInLGPD = useMemo(() => optInLGPD, [optInLGPD]);

    return (
        <AuthContext.Provider value={{
            userId: userId,
            email: userEmail,
            name: userName,
            roles: userRoles,
            courses: userCourses,
            expiration: userExpiration,
            optInLGPD: userOptInLGPD,
            isAuthenticated,
            login: handleLogin,
            logout: handleLogout,
            updateOptInLGPD: handleUpdateOptInLGPD
        }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuthContext = () => useContext(AuthContext);
