import { Environment } from "../../../environment";
import {
    IUserSearchResponse, IUserResponse, ICourseUserSearchResponse, ICourseUserPost, ICourseUserResponse,
    IRolePost, ILoginPost, ILoginResponse, IRole, IOptInLGPDPut, IStudentExtensionProjectSearchResponse,
    IUpdateStatusToReportedWorkloadPut,
    ICancelUserParticipationPut
} from "../../../interfaces";
import { Api } from "../axios-config";

const controller = "User"

const search = async (name?: string, rowsPerPage: number = Environment.ROWS_LIMIT10, page: number = 0): Promise<IUserSearchResponse | Error> => {

    try {

        const { data } = await Api.get<IUserSearchResponse>(`${controller}?Name=${name}&RowsPerPage=${rowsPerPage}&Page=${page}`);

        if (data) {
            return data;
        }

        return new Error("Erro ao buscar os usuários.");

    } catch (error) {
        console.error(error);

        return new Error((error as { message: string }).message || "Erro ao buscar os usuários.");
    }
}

const getById = async (id: number): Promise<IUserResponse | Error> => {

    try {

        const { data } = await Api.get<IUserResponse>(`${controller}/${id}`);

        if (data) {
            return data;
        }

        return new Error("Erro ao buscar o usuário.");

    } catch (error) {
        console.error(error);

        return new Error((error as { message: string }).message || "Erro ao buscar o usuário.");
    }

}

const getCoursesByUser = async (id: number): Promise<ICourseUserSearchResponse | Error> => {

    try {

        const { data } = await Api.get<ICourseUserSearchResponse>(`${controller}/GetCoursesByUser/${id}`);

        if (data) {
            return data;
        }

        return new Error("Erro ao buscar os cursos do usuário.");

    } catch (error) {
        console.error(error);

        return new Error((error as { message: string }).message || "Erro ao buscar cursos do usuário.");
    }

}

const addCourseUser = async (courseUser: ICourseUserPost): Promise<ICourseUserResponse | Error> => {

    try {

        const { data } = await Api.post<ICourseUserResponse>(`${controller}/AddCourseUser`, courseUser);

        if (data) {
            return data;
        }

        return new Error("Erro ao adicionar o curso ao usuário.");

    } catch (error) {
        console.error(error);

        return new Error((error as { message: string }).message || "Erro ao adicionar curso ao usuário.");
    }

}

const addRole = async (rolePost: IRolePost): Promise<any | Error> => {

    try {

        await Api.post(`${controller}/AddRoleToUser`, rolePost);

    } catch (error) {
        console.error(error);

        return new Error((error as { message: string }).message || "Erro ao adicionar a permissão ao usuário.");
    }

}

const deleteCourseUser = async (courseUser: ICourseUserPost): Promise<any | Error> => {

    try {

        await Api.delete(`${controller}/DeleteCourseUser/${courseUser.userId}/${courseUser.courseId}`);

    } catch (error) {
        console.error(error);

        return new Error((error as { message: string }).message || "Erro ao remover curso do usuário.");
    }

}

const deleteRoleUser = async (rolePost: IRolePost): Promise<any | Error> => {

    try {

        await Api.delete(`${controller}/RemoveRoleFromUser/${rolePost.userId}/${rolePost.roleName}`);

    } catch (error) {
        console.error(error);

        return new Error((error as { message: string }).message || "Erro ao remover curso do usuário.");
    }

}

const authorize = async (loginPost: ILoginPost): Promise<ILoginResponse | Error> => {

    try {

        const { data } = await Api.post<ILoginResponse>(`${controller}/Authorize`, loginPost);

        if (data) {
            return data;
        }

        return new Error("Erro ao autenticar.");

    } catch (error) {
        console.error(error);

        return new Error((error as { message: string }).message || "Erro ao autenticar.");
    }

}

const getRolesByUser = async (id: number): Promise<string[] | Error> => {

    try {

        const { data } = await Api.get<string[]>(`${controller}/GetRolesByUser/${id}`);

        if (data) {
            return data;
        }

        return [];

    } catch (error) {
        console.error(error);

        return new Error((error as { message: string }).message || "Erro ao buscar as permissões do usuário.");
    }

}

const getAllRoles = async (): Promise<IRole[] | Error> => {

    try {

        const { data } = await Api.get<IRole[]>(`${controller}/GetAllRoles`);

        if (data) {
            return data;
        }

        return new Error("Erro ao buscar as permissões.");

    } catch (error) {
        console.error(error);

        return new Error((error as { message: string }).message || "Erro ao buscar as permissões.");
    }

}

const optInLGPD = async (optInLGPDPut: IOptInLGPDPut): Promise<ILoginResponse | Error> => {

    try {

        const { data } = await Api.put(`${controller}/OptInLGPD`, optInLGPDPut);

        if (data) {
            return data;
        }

        return new Error("Erro ao fazer o aceite do termo de consentimento.");

    } catch (error) {
        console.error(error);

        return new Error((error as { message: string }).message || "Erro ao autenticar.");
    }

}

const getStudendStatusPendingDeductionOfHours = async (
    userName?: string,
    registration?: string,
    finalized?: boolean,
    listCourseId?: string,
    page: number = 0,
): Promise<IStudentExtensionProjectSearchResponse | Error> => {

    try {

        var request = `${controller}/GetStudendStatusPendingDeductionOfHours?`;

        if (finalized) {
            request += `Status=${3}`;
        }
        else {
            request += `Status=${2}`;
        }

        if (userName !== undefined) {
            request += `&UserName=${userName}`;
        }

        if (registration !== undefined) {
            request += `&Registration=${registration}`;
        }

        if (listCourseId) {
            listCourseId.split(',').forEach((courseId) => {
                request += `&ListCourseId=${courseId}`;
            });
        }

        request += `&RowsPerPage=${Environment.ROWS_LIMIT10}&Page=${page}`;

        const { data } = await Api.get<IStudentExtensionProjectSearchResponse>(request);

        if (data) {
            return data;
        }

        return new Error("Erro ao buscar os usuários.");

    } catch (error) {
        console.error(error);

        return new Error((error as { message: string }).message || "Erro ao buscar os usuários.");
    }
}

const getStudendParticipations = async (
    userId?: number,
    page: number = 0,
): Promise<IStudentExtensionProjectSearchResponse | Error> => {

    try {

        var request = `${controller}/GetStudendStatusPendingDeductionOfHours?UserId=${userId}`;

        request += `&RowsPerPage=999&Page=${page}`;

        const { data } = await Api.get<IStudentExtensionProjectSearchResponse>(request);

        if (data) {
            return data;
        }

        return new Error("Erro ao buscar os usuários.");

    } catch (error) {
        console.error(error);

        return new Error((error as { message: string }).message || "Erro ao buscar os usuários.");
    }
}

const updateStatusToReportedWorkload = async (request: IUpdateStatusToReportedWorkloadPut): Promise<any | Error> => {

    try {

        await Api.put(`${controller}/UpdateStatusToReportedWorkload`, request);

        return undefined;

    } catch (error) {
        console.error(error);

        return new Error((error as { message: string }).message || "Erro ao atualizar status do usuário.");
    }
}

const cancelStudentParticipation = async (request: ICancelUserParticipationPut): Promise<any | Error> => {

    try {

        await Api.put(`${controller}/CancelStudentParticipation`, request);

        return undefined;

    } catch (error) {
        console.error(error);

        return new Error((error as { message: string }).message || "Erro ao cancelar participação do usuário.");
    }
}

const cancelTeacherParticipation = async (request: ICancelUserParticipationPut): Promise<any | Error> => {

    try {

        await Api.put(`${controller}/CancelTeacherParticipation`, request);

        return undefined;

    } catch (error) {
        console.error(error);

        return new Error((error as { message: string }).message || "Erro ao cancelar participação do usuário.");
    }
}

const GetSuggestedWorkload = async (userId: number, extensionProjectId: number): Promise<number | Error> => {

    try {

        const { data } = await Api.get(`${controller}/GetSuggestedWorkload/${userId}/${extensionProjectId}`);

        if (data) {
            return data;
        }

        return new Error("Erro ao buscar as horas sugeridas.");

    } catch (error) {
        console.error(error);

        return new Error((error as { message: string }).message || "Erro ao buscar as horas sugeridas.");
    }
}

export const UserService = {
    search,
    getById,
    getCoursesByUser,
    getRolesByUser,
    getAllRoles,
    getStudendParticipations,
    addCourseUser,
    addRole,
    deleteCourseUser,
    deleteRoleUser,
    authorize,
    optInLGPD,
    getStudendStatusPendingDeductionOfHours,
    updateStatusToReportedWorkload,
    GetSuggestedWorkload,
    cancelStudentParticipation,
    cancelTeacherParticipation
};