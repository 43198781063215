import { Chip } from '@mui/material';

interface ParticipationStatusChipProps {
    status: number;
}

export const ParticipationStatusChip: React.FC<ParticipationStatusChipProps> = ({ status }) => {

    const getStatusLabel = (status: number): {
        label: string;
        color:
        'primary' |
        'secondary' |
        'warning' |
        'error' |
        'success' |
        'info'
    } => {

        switch (status) {
            case 0:
                return { label: 'Aprovação Pendente', color: 'warning' };
            case 1:
                return { label: 'Participando', color: 'info' };
            case 2:
                return { label: 'Aguardando Dedução de Horas', color: 'secondary' };
            case 3:
                return { label: 'Carga Horária Reportada', color: 'success' };
            case 4:
                return { label: 'Reprovado', color: 'error' };
            case 5:
                return { label: 'Finalizado', color: 'success' };
            case 6:
                return { label: 'Cancelado', color: 'error' };
            case 7:
                return { label: 'Transferência de responsabilidade', color: 'warning' };
            case 8:
                return { label: 'Remoção de responsabilidade', color: 'warning' };
            default:
                return { label: 'Desconhecido', color: 'secondary' };
        }

    };

    const { label, color } = getStatusLabel(status);

    return (
        <Chip label={label} color={color} size="small"/>
    );
};
