import { Environment } from "../../../environment";
import { Api } from "../axios-config"

const controller = "ReportManagement"

const extensionProjectsByCourse = async (
    name: string,
    userId: number = 0,
    status?: number,
    openForApplication?: boolean,
    listCourseId?: string,
    rowsPerPage: number = Environment.ROWS_LIMIT10,
    page: number = 0
): Promise<any | Error> => {

    try {

        var request = `${controller}/ExtensionProjectsByCourse?Name=${name}`;

        if (userId > 0) {
            request += `&UserId=${userId}`;
        }

        if (status !== undefined) {
            request += `&Status=${status}`;
        }

        if (openForApplication !== undefined) {
            request += `&OpenForApplications=${openForApplication}`;
        }

        if (listCourseId) {
            listCourseId.split(',').forEach((courseId) => {
                request += `&ListCourseId=${courseId}`;
            });
        }

        var response = await Api.get<File>(`${request}&RowsPerPage=${rowsPerPage}&Page=${page}`, {
            responseType: 'blob',
            headers: {
                'Accept': 'application/pdf'

            }
        });

        const contentDisposition = response.headers['content-disposition'];
        const match = contentDisposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/);

        var fileName = match ? decodeURIComponent(match[1].replace(/\+/g, ' ')) : 'report.pdf';

        fileName = fileName.replace(/^"(.*)"$/, '$1');

        const blob = new Blob([response.data], { type: 'application/pdf' });

        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;

        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        window.URL.revokeObjectURL(link.href);

        return null;

    } catch (error) {
        console.error(error);

        return new Error((error as { message: string }).message || "Erro ao fazer download do relatório.");
    }
}

const generateStudentCertificate = async (): Promise<any | Error> => {

    try {

        var response = await Api.get<File>(`${controller}/GenerateStudentCertificate`, {
            responseType: 'blob',
            headers: {
                'Accept': 'application/pdf'
            }
        });

        if(response.data instanceof Blob && response.data.size === 0)
        {
            return new Error("Aluno não possui certificado de participação.");
        }

        const contentDisposition = response.headers['content-disposition'];
        const match = contentDisposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/);

        var fileName = match ? decodeURIComponent(match[1].replace(/\+/g, ' ')) : 'GEEU - Certificado de participacao.pdf';

        fileName = fileName.replace(/^"(.*)"$/, '$1');

        const blob = new Blob([response.data], { type: 'application/pdf' });

        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;

        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        window.URL.revokeObjectURL(link.href);

        return null;

    } catch (error) {
        return new Error((error as { message: string }).message || "Erro ao fazer download do relatório.");
    }
}

const validateCertificate = async (hash: string): Promise<boolean | Error> => {

    try {

        const { data } = await Api.get<boolean>(`${controller}/ValidateCertificate/${hash}`);

        if (typeof data === 'boolean') {
            return data;
        }

        return new Error("Erro ao validar certificado.");

    } catch (error) {
        console.error(error);

        return new Error((error as { message: string }).message || "Erro ao validar certificado.");
    }
}

const downloadCertificate = async (hash: string): Promise<any | Error> => {

    try {

        var response = await Api.get<File>(`${controller}/DownloadCertificate/${hash}`, {
            responseType: 'blob',
            headers: {
                'Accept': 'application/pdf'
            }
        });

        const contentDisposition = response.headers['content-disposition'];
        const match = contentDisposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/);

        var fileName = match ? decodeURIComponent(match[1].replace(/\+/g, ' ')) : 'GEEU - Certificado de participacao.pdf';

        fileName = fileName.replace(/^"(.*)"$/, '$1');

        const blob = new Blob([response.data], { type: 'application/pdf' });

        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;

        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        window.URL.revokeObjectURL(link.href);

        return null;

    } catch (error) {
        console.error(error);

        return new Error((error as { message: string }).message || "Erro ao fazer download do relatório.");
    }
}

export const ReportManagementService
 = {
    extensionProjectsByCourse,
    generateStudentCertificate,
    downloadCertificate,
    validateCertificate
};