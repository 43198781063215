import { useEffect, useState } from "react";
import {
    Box, Button, Checkbox, FormControl, Icon, IconButton, InputLabel,
    ListItemText, MenuItem, OutlinedInput, Paper, Select, SelectChangeEvent,
    TextField, useMediaQuery, Accordion, AccordionSummary, AccordionDetails, Theme,
    Menu
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import { Environment } from "../../environment";
import { ICourseResponse } from "../../interfaces";
import { CourseService } from "../../services/api";

const statusEnum = [
    { id: 0, name: "Pendente de aprovação" },
    { id: 2, name: "Reprovado para revisão" },
    { id: 3, name: "Reprovado" },
    { id: 4, name: "Em andamento" },
    { id: 6, name: "Concluído" }
];

interface IListingToolbarProps {
    searchText?: string;
    status?: string;
    openForApplication?: string;
    listCourseIds?: string;
    onChangeSearchText?: (newText: string) => void;
    onChangeStatus?: (newText: string) => void;
    onChangeOpenForApplication?: (newText: string) => void;
    onChangeListCourseIds?: (newText: string) => void;
    textButton?: string;
    showButton?: boolean;
    isStudent?: boolean;
    disableButtonReport?: boolean;
    disableButtonSearch?: boolean;
    disableButtonGenerateCertificate?: boolean;
    onClickButtonAdd?: () => void;
    onClickSearch: () => void;
    onClickReport: () => void;
}

export const ListingExtensionProjectToolbar: React.FC<IListingToolbarProps> = ({
    searchText = '',
    status = "",
    openForApplication = "",
    listCourseIds = "",
    onChangeSearchText,
    onChangeStatus,
    onChangeOpenForApplication,
    onChangeListCourseIds,
    textButton = 'Novo',
    showButton = true,
    isStudent = false,
    disableButtonReport = true,
    disableButtonSearch = false,
    disableButtonGenerateCertificate = false,
    onClickButtonAdd,
    onClickSearch,
    onClickReport
}) => {
    const [selectedCourseIds, setSelectedCourseIds] = useState<number[]>([]);
    const [allCourses, setAllCourses] = useState<ICourseResponse[]>([]);
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    useEffect(() => {
        handleGetAllCourses();
        if (listCourseIds) {
            setSelectedCourseIds(listCourseIds.split(',').map(v => parseInt(v)));
        }
    }, []);

    const handleGetAllCourses = () => {
        if (isStudent) return;
        CourseService.getAll().then(result => {
            if (!(result instanceof Error)) {
                setAllCourses(result);
            }
        });
    };

    const handleCourseChange = (event: SelectChangeEvent<number[]>) => {
        const value = event.target.value;
        onChangeListCourseIds?.(Array.isArray(value) ? value.join(',') : value);
        setSelectedCourseIds(
            typeof value === 'string' ? value.split(',').map(v => parseInt(v)) : value,
        );
    };

    const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    return (
        <Box
            gap={1}
            marginX={1}
            padding={1}
            paddingX={2}
            display="flex"
            alignItems="center"
            component={Paper}
            flexWrap="wrap"
            justifyContent="space-between"
        >
            {/* Para computadores: Campos agrupados à esquerda e botões à direita */}
            {!isMobile && (
                <>
                    <Box display="flex" alignItems="center" gap={2}>
                        <TextField
                            size="small"
                            value={searchText}
                            onChange={(e) => onChangeSearchText?.(e.target.value)}
                            placeholder={Environment.SEARCH_INPUT}
                        />

                        {!isStudent && (
                            <>
                                <FormControl sx={{ minWidth: 120 }} size="small">
                                    <InputLabel>Status</InputLabel>
                                    <Select
                                        value={status}
                                        label="Status"
                                        onChange={(e) => onChangeStatus?.(e.target.value)}
                                    >
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                        {statusEnum.map((status) => (
                                            <MenuItem key={status.id} value={status.id}>
                                                <ListItemText primary={status.name} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>

                                <FormControl sx={{ minWidth: 200 }} size="small">
                                    <InputLabel>Aberto para Inscrição</InputLabel>
                                    <Select
                                        value={openForApplication}
                                        label="Aberto para Inscrição"
                                        onChange={(e) => onChangeOpenForApplication?.(e.target.value)}
                                    >
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                        <MenuItem key="true" value="true">Sim</MenuItem>
                                        <MenuItem key="false" value="false">Não</MenuItem>
                                    </Select>
                                </FormControl>

                                <FormControl sx={{ minWidth: 200 }} size="small">
                                    <InputLabel>Curso</InputLabel>
                                    <Select
                                        multiple
                                        value={selectedCourseIds}
                                        onChange={handleCourseChange}
                                        input={<OutlinedInput label="Curso" />}
                                        renderValue={(selected) => selected.join(', ')}
                                    >
                                        {allCourses.map((course) => (
                                            <MenuItem key={course.id} value={course.id}>
                                                <Checkbox checked={selectedCourseIds.indexOf(course.id) > -1} />
                                                <ListItemText primary={course.name} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </>
                        )}
                    </Box>

                    <Box display="flex" gap={1} justifyContent="flex-end">
                        <Button
                            variant="contained"
                            color="primary"
                            endIcon={<Icon>search</Icon>}
                            onClick={onClickSearch}
                            disabled={disableButtonSearch}
                            size="small"
                        >
                            Pesquisar
                        </Button>

                    </Box>
                </>
            )}

            {/* Para celulares: O campo de texto ocupa toda a largura e os demais campos no Accordion */}
            {isMobile && (
                <Box display="flex" flexDirection="column" width="100%">
                    <TextField
                        size="small"
                        fullWidth
                        value={searchText}
                        onChange={(e) => onChangeSearchText?.(e.target.value)}
                        placeholder={Environment.SEARCH_INPUT}
                    />

                    {!isStudent && (
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMore />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                Filtros Avançados
                            </AccordionSummary>
                            <AccordionDetails>
                                <FormControl sx={{ minWidth: 120, mb: 2 }} size="small">
                                    <InputLabel>Status</InputLabel>
                                    <Select
                                        value={status}
                                        label="Status"
                                        onChange={(e) => onChangeStatus?.(e.target.value)}
                                    >
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                        {statusEnum.map((status) => (
                                            <MenuItem key={status.id} value={status.id}>
                                                <ListItemText primary={status.name} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>

                                <FormControl sx={{ minWidth: 200, mb: 2 }} size="small">
                                    <InputLabel>Aberto para Inscrição</InputLabel>
                                    <Select
                                        value={openForApplication}
                                        label="Aberto para Inscrição"
                                        onChange={(e) => onChangeOpenForApplication?.(e.target.value)}
                                    >
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                        <MenuItem key="true" value="true">Sim</MenuItem>
                                        <MenuItem key="false" value="false">Não</MenuItem>
                                    </Select>
                                </FormControl>

                                <FormControl sx={{ minWidth: 300 }} size="small">
                                    <InputLabel>Curso</InputLabel>
                                    <Select
                                        multiple
                                        value={selectedCourseIds}
                                        onChange={handleCourseChange}
                                        input={<OutlinedInput label="Curso" />}
                                        renderValue={(selected) => selected.join(', ')}
                                    >
                                        {allCourses.map((course) => (
                                            <MenuItem key={course.id} value={course.id}>
                                                <Checkbox checked={selectedCourseIds.indexOf(course.id) > -1} />
                                                <ListItemText primary={course.name} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </AccordionDetails>
                        </Accordion>
                    )}

                    <Box display="flex" justifyContent="flex-end" mt={2}>
                        <IconButton color="primary" size="small" onClick={onClickSearch}>
                            <Icon>search</Icon>
                        </IconButton>

                        {
                            !isStudent && (
                                <>
                                    <IconButton
                                        color="primary"
                                        size="small"
                                        onClick={handleMenuOpen}
                                    >
                                        <Icon>more_vert</Icon>
                                    </IconButton>
                                    <Menu
                                        anchorEl={anchorEl}
                                        open={Boolean(anchorEl)}
                                        onClose={handleMenuClose}
                                    >
                                        {!isStudent && (
                                            <MenuItem
                                                onClick={() => {
                                                    onClickReport();
                                                    handleMenuClose();
                                                }}
                                                disabled={disableButtonReport}
                                            >
                                                <Icon>summarize</Icon> Gerar Relatório
                                            </MenuItem>
                                        )}

                                        {showButton && (
                                            <MenuItem
                                                onClick={onClickButtonAdd}
                                                disabled={disableButtonGenerateCertificate}
                                            >
                                                <Icon>add</Icon> {textButton}
                                            </MenuItem>
                                        )}
                                    </Menu>
                                </>
                            )
                        }


                    </Box>
                </Box>
            )}
        </Box>
    );
};
