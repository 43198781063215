import { ReactNode, createContext, useCallback, useContext, useMemo, useState } from "react";
import { ThemeProvider, Box, CssBaseline } from "@mui/material";
import { LightTheme, DarkTheme } from "../themes";
import { Environment } from "../environment";

interface IThemeContextData {
    themeName: 'light' | 'dark';
    toggleTheme: () => void;
}

interface IAppThemeProviderProps {
    children: ReactNode
}

const ThemeContext = createContext({} as IThemeContextData);

export const useAppThemeContext = () => {
    return useContext(ThemeContext);
}

export const AppThemeProvider: React.FC<IAppThemeProviderProps> = ({ children }) => {


    const [themeName, setThemeName] = useState<'light' | 'dark'>(() => {
        const storedTheme = localStorage.getItem(Environment.THEME) as 'light' | 'dark';
        const prefersColorScheme = window.matchMedia('(prefers-color-scheme: dark)');
        return storedTheme || (prefersColorScheme.matches ? 'dark' : 'light');
    });

    const toggleTheme = useCallback(() => {
        localStorage.setItem(Environment.THEME, themeName === 'light' ? 'dark' : 'light');
        setThemeName(oldThemeName => oldThemeName === 'light' ? 'dark' : 'light');
    }, [themeName]);

    const theme = useMemo(() => {
        return themeName === 'light' ? LightTheme : DarkTheme;
    }, [themeName]);

    return (
        <ThemeContext.Provider value={{ themeName, toggleTheme }}>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <Box width="100vw" height="100vh" bgcolor={theme.palette.background.default}>
                    {children}
                </Box>
            </ThemeProvider>
        </ThemeContext.Provider>
    );
}