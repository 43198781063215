
import {
    Avatar, CardContent, Container,
    Grid, IconButton, Link, Tooltip, Typography
} from "@mui/material";
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GitHubIcon from '@mui/icons-material/GitHub';
import YouTubeIcon from '@mui/icons-material/YouTube';
import PublicIcon from '@mui/icons-material/Public';

import { BasePageLayout } from "../../shared/layouts";

export const About = () => {

    return (
        <BasePageLayout
            title="Sobre o projeto"
        >
            <Container maxWidth="lg">
                {/* Cabeçalho */}
                <Typography variant="h2" component="h1" gutterBottom>
                    Sobre o Projeto
                </Typography>

                <Typography variant="subtitle1" gutterBottom sx={{ textAlign: 'justify' }}>
                    Este trabalho tem como objetivo ser um sistema de informação para
                    auxílio, controle e gerenciamento das atividades relacionadas aos programas e projetos de
                    extensão, que é regimentada e é obrigatória de acordo com a resolução n.07 de 18 de
                    dezembro de 2018, do Conselho Nacional de Educação (CNE). O sistema envolve o
                    controle de docentes, discentes, cursos e atividades (sejam elas projetos, programas,
                    disciplinas etc.) que estiverem relacionados às atividades de extensão da instituição.
                    <br />
                    <br />
                    Tive a oportunidade de desenvolver este projeto como meu Trabalho de Conclusão de Curso
                    no curso de Sistemas de Informação da Universidade Federal de Ouro Preto e o enorme prazer de ter sido
                    orientado pelo Prof. Dr. Rafael Frederico Alexandre e co-orientado pelo Prof. Dr. Mateus Ferreira Satler.
                    <br />
                    <br />
                    A stack usada para o desenvolvimento deste projeto foi ASP.NET Core, React, Material-UI e PostgreSQL.
                </Typography>

                <br />
                <br />
                <Grid container spacing={4} alignItems="center">
                    <Grid item xs={12} md={4}>
                        <Avatar
                            alt="Jordan Bragon"
                            src="https://avatars.githubusercontent.com/u/14347147?v=4"
                            sx={{ width: 200, height: 200 }}
                        />
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                                Olá! Eu sou Jordan Bragon...
                            </Typography>
                            <Typography variant="body1" color="text.secondary" sx={{ textAlign: 'justify' }}>
                                Desenvolvedor a mais de 8 anos e apaixonado por tecnologia.
                                Sou formado pela Universidade Federal de Ouro Preto no Curso de Sistemas de Informação.
                                Minha stack principal é ASP.NET Core.

                                Durante todo o desenvolvimento deste projeto, compartilhei todo o processo de desenvolvimento
                                em lives na Twitch. Se você quiser ver todo o processo de desenvolvimento, acesse o link do youtube ao lado
                                para assistir as gravações.
                            </Typography>
                        </CardContent>
                    </Grid>
                </Grid>

                <Typography variant="h5" component="h2" gutterBottom>
                    Conecte-se Comigo
                </Typography>

                <Grid container spacing={2}>

                    <Grid item>
                        <IconButton component={Link} href="https://www.linkedin.com/in/jordanbragon/" target="_blank">
                            <LinkedInIcon fontSize="large" color="primary" />
                        </IconButton>
                    </Grid>

                    <Grid item>
                        <IconButton component={Link} href="https://github.com/jbragon" target="_blank">
                            <GitHubIcon fontSize="large" color="primary" />
                        </IconButton>
                    </Grid>

                    <Grid item>
                        <IconButton
                            component={Link}
                            href="https://youtube.com/@bragondev"
                            target="_blank"
                        >
                            <YouTubeIcon fontSize="large" color="primary" />
                        </IconButton>
                    </Grid>

                    <Grid item>
                        <Tooltip title="Twitch">

                            <IconButton
                                component={Link}
                                href="https://www.twitch.tv/bragondev"
                                target="_blank"
                            >
                                <PublicIcon fontSize="large" color="primary" />
                            </IconButton>

                        </Tooltip>

                    </Grid>
                </Grid>
            </Container>
        </BasePageLayout>
    )
}