import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Grid, LinearProgress, Paper, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import * as yup from 'yup';

import { DetailToolbar } from "../../shared/components";
import { useVForm, IVFormErrors, VForm, VTextField } from "../../shared/forms";
import { IDepartmentPost } from "../../shared/interfaces";
import { BasePageLayout } from "../../shared/layouts";
import { DepartmentService } from "../../shared/services/api";

const formValidationSchema: yup.Schema<IDepartmentPost> = yup.object().shape({
    name: yup
        .string()
        .required()
        .min(5)
        .max(50),
    acronym: yup
        .string()
        .required()
        .min(2)
        .max(10),
});

export const DepartmentForm: React.FC = () => {
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    const {
        formRef, save, saveAndBack, isSaveAndBack
    } = useVForm();

    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        formRef.current?.setData({
            nome: ''
        });
    }, [formRef])

    const handleSave = (formData: IDepartmentPost) => {

        formValidationSchema.
            validate(formData, { abortEarly: false })
            .then((validateDatas) => {

                setLoading(true);

                DepartmentService.create(validateDatas)
                    .then((result) => {

                        if (result instanceof Error) {
                            return;
                        }

                        enqueueSnackbar(
                            <Typography>
                                Departamento cadastrado com sucesso.
                            </Typography>,
                            { variant: 'success' });

                        if (isSaveAndBack())
                            navigate("/departments");

                    })
                    .finally(() => {
                        setLoading(false);
                    }); setLoading(true);

            })
            .catch((errors: yup.ValidationError) => {
                const validationErrors: IVFormErrors = {}

                errors.inner.forEach(error => {

                    if (!error.path) return;

                    validationErrors[error.path] = error.message;
                });

                formRef.current?.setErrors(validationErrors);
            });

    };

    return (
        <BasePageLayout
            title="Novo Departamento"
            toolbar={
                <DetailToolbar
                    showButtonDelete={false}
                    showButtonAdd={false}
                    showButtonSaveAndBack
                    showButtonSaveLoading={loading}
                    showButtonSaveAndBackLoading={loading}
                    showButtonBackLoading={loading}

                    onClickButtonSave={save}
                    onClickButtonSaveAndBack={saveAndBack}
                    onClickButtonBack={() => navigate("/departments", { replace: true })}
                />
            }
        >

            <VForm ref={formRef} onSubmit={handleSave}>
                <Box margin={1} display="flex" flexDirection="column" component={Paper}>


                    <Grid container direction="column" padding={1} spacing={2}>

                        {
                            loading &&
                            <Grid item>
                                <LinearProgress variant='indeterminate' />
                            </Grid>
                        }
                        <Grid item>
                            <Typography variant="h6">Dados do departamento</Typography>
                        </Grid>

                        <Grid container item direction="row" spacing={2}>
                            <Grid item
                                xs={12}
                                md={8}
                                lg={6}
                                xl={3}
                            >
                                <VTextField
                                    label="Nome"
                                    name="name"
                                    fullWidth
                                    required
                                    toUpper
                                    disabled={loading}
                                />
                            </Grid>
                        </Grid>

                        <Grid container item direction="row" spacing={2}>
                            <Grid item
                                xs={4}
                                md={4}
                                lg={2}
                                xl={2}
                            >
                                <VTextField
                                    label="Sigla"
                                    name="acronym"
                                    fullWidth
                                    required
                                    toUpper
                                    disabled={loading}
                                />
                            </Grid>
                        </Grid>

                    </Grid>

                </Box>
            </VForm>

        </BasePageLayout>
    )
};